import * as React from 'react';
import * as RadixSelect from '@radix-ui/react-select';
import { cva, type VariantProps } from 'class-variance-authority';

import { Icon } from '../Icon';
import { IconButton } from '../IconButton';
import { Input } from '../Input';
import { cn, useFilter, useForwardedRef, useId } from '../utils';

export type SelectFieldProps = Omit<RadixSelect.SelectProps, 'children'> &
  VariantProps<typeof triggerStyle> & {
    id?: string;
    label?: React.ReactNode;
    errorMessage?: string;
    iconBefore?: string;
    placeholder?: string;
    isFilterable?: boolean;
    isClearable?: boolean;
    options?: Readonly<SelectFieldOption[]>;
    collisionPadding?: number;
    wrapperClassName?: string;
    labelClassName?: string;
    fieldClassName?: string;
    valueClassName?: string;
    placeholderClassName?: string;
    errorClassName?: string;
    contentClassName?: string;
  };

export type SelectFieldOption = {
  value: string;
  label: string;
  textValue?: string;
  className?: string;
  iconBefore?: string;
  iconAfter?: string;
} & VariantProps<typeof itemStyle>;

export const SelectField = React.forwardRef<HTMLButtonElement, SelectFieldProps>(function Select(props, ref) {
  const {
    label,
    errorMessage,
    isFilterable,
    isClearable,
    options = [],
    placeholder = 'Select',
    collisionPadding = 8,
    value,
    onValueChange,
    wrapperClassName,
    labelClassName,
    errorClassName,
    fieldClassName,
    valueClassName,
    placeholderClassName,
    contentClassName,
    iconBefore,
    variant,
    size,
    disabled,
    defaultOpen = false,
    required,
    ...rest
  } = props;

  const fieldId = useId(props.id);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const { contains } = useFilter({
    sensitivity: 'base',
  });
  const filterFuncRef = React.useRef(contains); // "contains" is a new instance every render, so we need to put it in a ref to be able to memoize
  const [filter, setFilter] = React.useState('');
  const [selectedIndex, setSelectedIndex] = React.useState<number | undefined>(undefined);

  const [open, onOpenChange] = React.useState(defaultOpen);

  const filteredOptions = React.useMemo(() => {
    return options.filter(option => filterFuncRef.current(option.textValue || option.label, filter));
  }, [options, filter]);

  React.useEffect(() => {
    if (filteredOptions.length !== options.length) {
      setSelectedIndex(0);
    }
  }, [filteredOptions.length, options.length]);

  const onInputKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      if (propagateKeys.includes(e.key) === false) {
        e.stopPropagation();
      }
      if (e.key === 'ArrowDown') {
        e.stopPropagation();
        setSelectedIndex(i => {
          if (i === undefined) return 0;
          return Math.min(i + 1, filteredOptions.length - 1);
        });
      }
      if (e.key === 'ArrowUp') {
        e.stopPropagation();
        setSelectedIndex(i => {
          if (i === undefined) return 0;
          return Math.max(i - 1, 0);
        });
      }
      if (e.key === 'Enter') {
        e.stopPropagation();
        if (selectedIndex !== undefined) {
          onValueChange?.(filteredOptions[selectedIndex].value);
          setFilter('');
          setSelectedIndex(undefined);
          onOpenChange(false);
        }
      }
    },
    [filteredOptions, onValueChange, selectedIndex]
  );

  const currentOption = React.useMemo(() => {
    return options.find(option => option.value === value);
  }, [value, options]);

  const innerOpenChange = React.useCallback((open: boolean) => {
    if (open) requestAnimationFrame(() => inputRef.current?.focus());
    if (open === false) {
      setFilter('');
      setSelectedIndex(undefined);
    }
    onOpenChange(open);
  }, []);

  return (
    <Input.Wrapper className={wrapperClassName}>
      {label && (
        <Input.Label htmlFor={fieldId} className={labelClassName}>
          {label}
        </Input.Label>
      )}
      <RadixSelect.Root
        {...rest}
        open={open}
        value={value}
        onValueChange={isFilterable ? () => undefined : onValueChange}
        onOpenChange={innerOpenChange}
      >
        <RadixSelect.Trigger
          ref={ref}
          id={fieldId}
          className={cn(triggerStyle({ disabled, variant, size }), fieldClassName)}
          aria-invalid={!!errorMessage}
          aria-errormessage={errorMessage ? `${fieldId}-error` : undefined}
          aria-required={required}
          disabled={disabled}
        >
          {iconBefore && <Icon className="flex-0 text-text-secondary" icon={iconBefore} />}
          <span className={cn('flex-1 flex-nowrap overflow-hidden text-ellipsis whitespace-nowrap', valueClassName)}>
            {currentOption?.label ?? (
              <span className={cn('text-grey-ds-500', placeholderClassName)}>{placeholder}</span>
            )}
          </span>
          {isClearable && value && (
            <IconButton
              size="xsmall"
              variant="ghost"
              variantColor="secondary"
              icon="ic:outline-close"
              onPointerDown={e => {
                // radix uses this event, so we need to stop it
                e.stopPropagation();
                e.preventDefault();
              }}
              onClick={() => onValueChange?.('')}
              onKeyDown={e => {
                if (['Enter', ' '].includes(e.key)) {
                  e.stopPropagation();
                  onValueChange?.('');
                }
              }}
              // as div to avoid button inside of button (invalid html)
              asChild
            >
              <div tabIndex={0} />
            </IconButton>
          )}
          <RadixSelect.Icon asChild>
            <Icon className="flex-0 text-base leading-4" icon="ic:outline-expand-more" />
          </RadixSelect.Icon>
        </RadixSelect.Trigger>
        <RadixSelect.Portal className="z-50">
          <RadixSelect.Content
            sideOffset={2}
            collisionPadding={collisionPadding}
            position="popper"
            className={cn(
              'flex max-h-[var(--radix-select-content-available-height)] flex-col rounded bg-white p-2 shadow-dropdown',
              contentClassName
            )}
          >
            {isFilterable && (
              <Input.FieldGroup className="mb-1">
                <Icon className="ml-1 text-base" icon="ic:baseline-search" />
                <Input.Field
                  onKeyDown={onInputKeyDown}
                  ref={inputRef}
                  value={filter}
                  onChange={e => setFilter(e.target.value)}
                />
              </Input.FieldGroup>
            )}
            <RadixSelect.Viewport>
              {isFilterable
                ? filteredOptions.map((option, index) => (
                    <FilterableSelectItem
                      key={option.value}
                      {...option}
                      selected={index === selectedIndex}
                      isCurrent={option.value === currentOption?.value}
                      onSelect={value => {
                        onValueChange?.(value);
                        innerOpenChange(false);
                      }}
                    />
                  ))
                : options.map(option => <SelectItem key={option.value} {...option} />)}
            </RadixSelect.Viewport>
          </RadixSelect.Content>
        </RadixSelect.Portal>
      </RadixSelect.Root>
      {errorMessage && (
        <Input.ErrorMessage id={`${fieldId}-error`} className={errorClassName}>
          {errorMessage}
        </Input.ErrorMessage>
      )}
    </Input.Wrapper>
  );
});

const propagateKeys = ['Enter', 'Escape'];

const SelectItem = React.forwardRef<HTMLDivElement, SelectFieldOption>(function SelectItem(props, forwardedRef) {
  const { label, className, unstyled, selected, ...rest } = props;
  return (
    <RadixSelect.Item ref={forwardedRef} className={cn(itemStyle({ unstyled, selected }), className)} {...rest}>
      <RadixSelect.ItemText>{label}</RadixSelect.ItemText>
      <RadixSelect.ItemIndicator className="absolute left-0 mx-0.5 text-sm">
        <Icon icon="ic:outline-check" />
      </RadixSelect.ItemIndicator>
    </RadixSelect.Item>
  );
});

const FilterableSelectItem = React.forwardRef<
  HTMLDivElement,
  SelectFieldOption & { onSelect?: (value: string) => void; isCurrent?: boolean }
>(function FilterableSelectItem(props, forwardedRef) {
  const { label, textValue, className, unstyled, selected, onSelect, value, isCurrent, ...rest } = props;
  const ref = useForwardedRef(forwardedRef);
  React.useEffect(() => {
    if (selected) ref.current?.scrollIntoView({ block: 'nearest' });
  }, [selected, ref]);
  return (
    <div
      ref={ref}
      className={cn(itemStyle({ unstyled, selected }), className)}
      onClick={e => {
        e.stopPropagation();
        onSelect?.(value);
      }}
      role="option"
      aria-label={textValue || label}
      {...rest}
    >
      {isCurrent && <Icon className="absolute left-0 mx-0.5 text-sm" icon="ic:outline-check" />}
      {label}
    </div>
  );
});

const triggerStyle = cva('', {
  variants: {
    variant: {
      unstyled: '',
      primary:
        'flex items-center gap-2 rounded border border-border-medium bg-white px-2 py-[9px] text-left text-sm leading-4 outline-none focus:border-border-dark data-[state=open]:border-border-dark',
      secondary:
        'flex items-center gap-2 rounded bg-white px-2 py-2 text-left text-sm leading-5 shadow-card outline-none',
      flat: 'flex items-center gap-2',
    },
    disabled: {
      false: 'cursor-pointer',
      true: 'cursor-not-allowed',
    },
    size: {
      default: '',
      small: '',
      dense: 'h-6',
    },
  },
  defaultVariants: {
    size: 'default',
    variant: 'primary',
    disabled: false,
  },
});

const itemStyle = cva('cursor-pointer', {
  variants: {
    unstyled: {
      false: 'relative flex items-center gap-2 rounded-sm px-5 py-0.5 text-sm leading-6 outline-none',
    },
    selected: {
      true: 'bg-grey-50 text-black',
      false: 'text-grey-600 hover:bg-grey-50 hover:text-black focus-visible:bg-grey-50 focus-visible:text-black',
    },
  },
  defaultVariants: {
    unstyled: false,
    selected: false,
  },
});
