import { useLocation, useNavigate } from 'react-router-dom';

import { ActionSetPassword } from './ActionSetPassword';
import { ActionSignIn } from './ActionSignIn';
import { ActionVerifyEmail } from './ActionVerifyEmail';

export const Action = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const mode = params.get('mode') || '';
  const actionCode = params.get('oobCode');
  const continueUrl = params.get('continueUrl');

  switch (mode) {
    case 'resetPassword':
      return <ActionSetPassword actionCode={actionCode || ''} continueUrl={continueUrl || '/'} />;
    case 'verifyEmail':
      return <ActionVerifyEmail actionCode={actionCode || ''} continueUrl={continueUrl || '/'} />;
    case 'signIn':
      return <ActionSignIn continueUrl={continueUrl || '/'} />;
    default:
      navigate('/404');
      return <p></p>;
  }
};
