export { mergeDeep } from './mergeDeep';
export * from './throttle-debounce';

/**
 * Filter out all null and undefined values from an array
 */
export function notEmptyArray<TValue>(values: (TValue | null | undefined)[]): TValue[] {
  function _notEmpty<T>(value: T | null | undefined): value is T {
    return value !== null && value !== undefined;
  }

  return values.filter(_notEmpty);
}

/**
 * Object.keys(), but actually typed
 */
export function TypedObjectKeys<T extends object>(object: T) {
  return Object.keys(object) as (keyof typeof object)[];
}

export function TypedObjectEntries<T extends object>(object: T) {
  return Object.entries(object) as [keyof T, T[keyof T]][];
}

/**
 * Convert all null in an object values to undefined,
 * and remove the null type from the object keys
 */
export function NullKeysToUndefined<T extends Record<string, any>>(obj: T): { [K in keyof T]: Exclude<T[K], null> } {
  return TypedObjectKeys(obj).reduce(
    (acc, key) => {
      if (obj[key] === null) {
        // @ts-expect-error yes this is what we want
        acc[key] = undefined;
      } else {
        acc[key] = obj[key];
      }
      return acc;
    },
    {} as { [K in keyof T]: Exclude<T[K], null> }
  );
}

export type DeepNullKeysToUndefined<T> = {
  [K in keyof T]: Exclude<T[K], null | undefined> extends Record<string, any>
    ? Exclude<DeepNullKeysToUndefined<T[K]>, null>
    : Exclude<T[K], null>;
};
export function DeepNullKeysToUndefined<T extends Record<string, any>>(obj: T): DeepNullKeysToUndefined<T> {
  return TypedObjectKeys(obj).reduce((acc, key) => {
    if (obj[key] === null) {
      // @ts-expect-error yes this is what we want
      acc[key] = undefined;
    } else if (Array.isArray(obj[key])) {
      acc[key] = obj[key].map((el: any) =>
        typeof el === 'object' && !Array.isArray(el) && el !== null ? DeepNullKeysToUndefined(el) : el
      );
    } else if (typeof obj[key] === 'object') {
      // @ts-expect-error yes this is what we want
      acc[key] = DeepNullKeysToUndefined(obj[key]);
    } else {
      acc[key] = obj[key];
    }

    return acc;
  }, {} as DeepNullKeysToUndefined<T>);
}

/** Union of primitives to skip with deep omit utilities. */
// eslint-disable-next-line @typescript-eslint/ban-types
type Primitive = string | Function | number | boolean | symbol | undefined | null;

/** Key Retrieval */

export type KeysOfType<T, U> = {
  [K in keyof T]: T[K] extends U ? K : never;
}[keyof T];

export type RequiredKeys<T> = Exclude<KeysOfType<T, Exclude<T[keyof T], undefined>>, undefined>;

export type OptionalKeys<T> = Exclude<keyof T, RequiredKeys<T>>;

/** Deeply omit members of an array of interface or array of type. */
export type DeepOmitArray<T extends any[], K> = {
  [P in keyof T]: DeepOmit<T[P], K>;
};

/** Deeply omit members of an interface or type. */
export type DeepOmit<T, K> = T extends Primitive
  ? T
  : {
      [P in Exclude<RequiredKeys<T>, K>]: T[P] extends infer TP // extra level of indirection needed to trigger homomorhic behavior // distribute over unions
        ? TP extends Primitive
          ? TP // leave primitives and functions alone
          : TP extends any[]
            ? DeepOmitArray<TP, K> // Array special handling
            : DeepOmit<TP, K>
        : never;
    } & {
      [P in Exclude<OptionalKeys<T>, K>]?: T[P] extends infer TP // extra level of indirection needed to trigger homomorhic behavior // distribute over unions
        ? TP extends Primitive
          ? TP // leave primitives and functions alone
          : TP extends any[]
            ? DeepOmitArray<TP, K> // Array special handling
            : DeepOmit<TP, K>
        : never;
    };

/** Deeply omit members of an array of interface or array of type, making all members optional. */
export type PartialDeepOmitArray<T extends any[], K> = Partial<{
  [P in Partial<keyof T>]: Partial<PartialDeepOmit<T[P], K>>;
}>;

/** Deeply omit members of an interface or type, making all members optional. */
export type PartialDeepOmit<T, K> = T extends Primitive
  ? T
  : Partial<{
      [P in Exclude<keyof T, K>]: T[P] extends infer TP // extra level of indirection needed to trigger homomorhic behavior // distribute over unions
        ? TP extends Primitive
          ? TP // leave primitives and functions alone
          : TP extends any[]
            ? PartialDeepOmitArray<TP, K> // Array special handling
            : Partial<PartialDeepOmit<TP, K>>
        : never;
    }>;

export const DeepRemoveTypename = <T extends Record<string, any>>(obj: T): DeepOmit<T, '__typename'> => {
  return TypedObjectKeys(obj).reduce(
    (acc, key) => {
      if (key === '__typename') {
        return acc;
      }
      if (Array.isArray(obj[key])) {
        // @ts-expect-error yes this is what we want
        acc[key] = obj[key].map(el =>
          typeof el === 'object' && !Array.isArray(el) && el !== null ? DeepRemoveTypename(el) : el
        );
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        // @ts-expect-error yes this is what we want
        acc[key] = DeepRemoveTypename(obj[key]);
      } else {
        // @ts-expect-error yes this is what we want
        acc[key] = obj[key];
      }
      return acc;
    },
    {} as DeepOmit<T, '__typename'>
  );
};

type NestedObject = {
  [key: string]: NestedObject | any;
};

type NestedKeyOf<T> = {
  [K in keyof T & (string | number)]: T[K] extends object ? `${K}` | `${K}.${NestedKeyOf<T[K]>}` : `${K}`;
}[keyof T & (string | number)];

export function getNestedValue<T extends NestedObject, P extends NestedKeyOf<T>>(obj: T, path: P): any {
  return path.split('.').reduce((current: any, key) => current && current[key], obj);
}

/**
 * @example 
    const backgroundColor = '#3498db'; // Replace with your background color
    const textColor = getContrastColor(backgroundColor);
 */
export function getContrastingTextColor(backgroundHex: string) {
  // Remove the # if present
  const hex = backgroundHex.replace('#', '');

  // Convert hex to RGB
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Calculate perceived brightness
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  // Use a threshold that works for the given examples
  return brightness > 153 ? '#000000' : '#FFFFFF';
}
