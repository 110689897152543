import { Link } from 'react-router-dom';

export const NotFound = () => {
  return (
    <div className="flex h-[70%] min-h-[300px] items-center justify-center">
      <div className="rounded-3xl p-10 shadow-card">
        <h1 className="text-2xl">Page Not found</h1>
        <h3 className="text-base">This page does not exist or has been moved.</h3>

        <Link to="/" className="text-grey700">
          Return to Homepage
        </Link>
      </div>
    </div>
  );
};
