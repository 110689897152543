import * as React from 'react';
import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import { cva, type VariantProps } from 'class-variance-authority';

import { Input } from '../Input';
import { cn, useId } from '../utils';

export type RadioGroupProps = RadixRadioGroup.RadioGroupProps & {
  label?: React.ReactNode;
  description?: React.ReactNode;
  errorMessage?: React.ReactNode;
  children?: React.ReactNode;
  wrapperClassName?: string;
  groupClassName?: string;
  labelClassName?: string;
  descriptionClassName?: string;
};

export type RadioProps = RadixRadioGroup.RadioGroupItemProps & VariantProps<typeof radioLabelStyles>;

const groupStyles = 'flex flex-col gap-4 p-3 text-xs font-normal';

const Group = React.forwardRef<HTMLDivElement, RadioGroupProps>(function Group(props, forwardedRef) {
  const {
    id,
    children,
    wrapperClassName,
    groupClassName,
    label,
    labelClassName,
    description,
    descriptionClassName,
    errorMessage,
    ...rest
  } = props;

  const inputId = useId(id);

  return (
    <Input.Wrapper className={cn(groupStyles, wrapperClassName)}>
      {label && (
        <Input.Label id={`${inputId}-label`} className={labelClassName}>
          {label}
        </Input.Label>
      )}
      {description && (
        <Input.Description id={`${inputId}-description`} className={descriptionClassName}>
          {description}
        </Input.Description>
      )}
      <RadixRadioGroup.Root
        ref={forwardedRef}
        {...rest}
        className={cn(groupStyles, 'p-0', groupClassName)}
        aria-labelledby={(label && `${inputId}-label`) || undefined}
        aria-describedby={(description && `${inputId}-description`) || undefined}
        aria-errormessage={(errorMessage && `${inputId}-error`) || undefined}
      >
        {children}
      </RadixRadioGroup.Root>
      {errorMessage && <Input.ErrorMessage id={`${inputId}-error`}>{errorMessage}</Input.ErrorMessage>}
    </Input.Wrapper>
  );
});

const radioLabelStyles = cva(['inline-flex items-center gap-2 leading-4'], {
  variants: {
    disabled: {
      true: 'text-grey-300',
    },
  },
  defaultVariants: {
    disabled: false,
  },
});

const radioOuterStyles = cva(['relative h-4 w-4 rounded-full border'], {
  variants: {
    disabled: {
      true: 'border-grey-500',
      false: 'border-black',
    },
  },
  defaultVariants: {
    disabled: false,
  },
});

const radioInnerStyles = cva(['absolute left-1/2 top-1/2 h-2 w-2 -translate-x-1/2 -translate-y-1/2 rounded-full'], {
  variants: {
    disabled: {
      false: 'bg-accent',
      true: 'bg-grey-500',
    },
  },
  defaultVariants: {
    disabled: false,
  },
});

function Radio(props: RadioProps) {
  const { children, className, disabled, value } = props;

  return (
    <label className={cn(radioLabelStyles({ disabled }), className)}>
      <RadixRadioGroup.Item value={value} className={radioOuterStyles({ disabled })}>
        <RadixRadioGroup.Indicator className={radioInnerStyles({ disabled })} />
      </RadixRadioGroup.Item>
      {children}
    </label>
  );
}

export const RadioGroup = { Group, Radio };
