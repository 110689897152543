import type { IconifyJSON } from '@iconify/react';

/**
 * Before adding new icons, please make sure with the designer(s) that there is not aready one available in Iconify that could be used instead.
 *
 * Adding icons:
 * - make sure the icon has a 24*24 viewbox. If not, resize the icon in figma before re-exporting it.
 * - optimize the icon using https://jakearchibald.github.io/svgomg/, will all options checked, number precision 3, and transform precision 5.
 * - replace the fill color with "currentColor"
 */

export const aaiCollection: IconifyJSON = {
  prefix: 'aai',
  width: 24,
  height: 24,
  icons: {
    'machine-learning': {
      body: '<path fill="currentColor" d="M9.861 2.012a1.001 1.001 0 0 0-.744.531c-.2.389-.121.86.194 1.164l5.054 4.887c.144.139.327.231.524.267l6.94 1.215a1 1 0 0 0 1.065-.525.996.996 0 0 0-.197-1.168l-5.052-4.887a1.016 1.016 0 0 0-.524-.267l-6.94-1.215a1.023 1.023 0 0 0-.32-.002ZM4 4c-1.11 0-2 .89-2 2v10a2 2 0 0 0 2 2h16c1.1 0 2-.9 2-2v-3.91c-.17 0-.34-.01-.52-.04l-.48-.08-1-.18V16H4V6h4.8l-.88-.85c-.05-.05-.1-.1-.14-.15-.06-.06-.11-.12-.16-.19a1.433 1.433 0 0 1-.14-.2c-.12-.19-.22-.4-.3-.61H4Zm6.34 3.49L9.08 9.97s1.64.83 3.27 3.03c2.75.03 4.39.86 4.39.86l1.24-2.42-3.45-.61a3 3 0 0 1-1.56-.8l-2.63-2.54ZM8 20v2h8v-2H8Z"/>',
    },
  },
};
