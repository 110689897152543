import { Button, Card, Heading } from '@aether/ui';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@/utils/auth';

export const Forbidden = () => {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const logOut = () => {
    signOut().then(() => navigate('/auth/login'));
  };

  return (
    <Card
      variant="hero"
      title="Your account has been created."
      data-testid="ac-UnauthorizedPage"
      className="-mx-4 max-w-4xl sm:mx-auto"
    >
      <div className="flex flex-col gap-4">
        <Heading>Congratulations, you have successfully signed up to Portfolio Dragon.</Heading>
        <p>
          However, before you can start using the app, we just need to check a few things to ensure everything goes
          smoothly.
        </p>
        <p>
          Please reach out to us to request access to the app, and we will get your account up and running as soon as
          possible.
        </p>
        <div>
          <Button onClick={logOut}>Log out</Button>
        </div>
      </div>
    </Card>
  );
};
