import * as Toast from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './overrides.css';

import { Icon } from '../Icon';

export function ToastController(props: Toast.ToastContainerProps) {
  return <Toast.ToastContainer bodyClassName="!p-0 !m-0" transition={Toast.Slide} {...props} />;
}

export const toast = {
  default: (content: Toast.ToastContent<unknown>, options?: Toast.ToastOptions<unknown> | undefined) =>
    Toast.toast(content, {
      className: '!rounded !border !px-3 !py-[11px] !text-sm !font-medium !border-grey-400 !bg-grey-50 !text-grey-700',
      progressClassName: '!bg-grey-400',
      ...options,
    }),
  info: (content: Toast.ToastContent<unknown>, options?: Toast.ToastOptions<unknown> | undefined) =>
    Toast.toast.info(content, {
      icon: (
        <div className="!my-px !text-[18px] !text-portfolio-500">
          <Icon icon="ic:outline-info" />
        </div>
      ),
      progressClassName: '!bg-portfolio-400',
      className:
        '!rounded !border !px-3 !py-[11px] !text-sm !font-medium !border-portfolio-400 !bg-portfolio-50 !text-portfolio-700',
      ...options,
    }),
  success: (content: Toast.ToastContent<unknown>, options?: Toast.ToastOptions<unknown> | undefined) =>
    Toast.toast.success(content, {
      icon: (
        <div className="!my-px !text-[18px] !text-success-500">
          <Icon icon="ic:outline-check-circle" />
        </div>
      ),
      progressClassName: '!bg-success-400',
      className:
        '!rounded !border !px-3 !py-[11px] !text-sm !font-medium !border-success-400 !bg-success-50 !text-success-700',
      ...options,
    }),
  warning: (content: Toast.ToastContent<unknown>, options?: Toast.ToastOptions<unknown> | undefined) =>
    Toast.toast.warning(content, {
      icon: (
        <div className="!my-px !text-[18px] !text-orange-500">
          <Icon icon="ic:outline-warning" />
        </div>
      ),
      progressClassName: '!bg-orange-400',
      className:
        '!rounded !border !px-3 !py-[11px] !text-sm !font-medium !border-orange-400 !bg-orange-50 !text-orange-700',
      ...options,
    }),
  error: (content: Toast.ToastContent<unknown>, options?: Toast.ToastOptions<unknown> | undefined) =>
    Toast.toast.error(content, {
      icon: (
        <div className="!my-px !text-[18px] !text-error-500">
          <Icon icon="ic:outline-error" />
        </div>
      ),
      progressClassName: '!bg-error-400',
      className:
        '!rounded !border !px-3 !py-[11px] !text-sm !font-medium !border-error-400 !bg-error-50 !text-error-700',
      ...options,
    }),
};
