import React from 'react';
import { initPosthog, PostHogProvider } from '@aether/tracking';
import * as ReactDOMClient from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import userflow from 'userflow.js';

// import 'unfonts.css';
import './globals.css';
import './index.scss';
import './Sentry';

import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';

import { App } from './App';
import { syncFeatureFlags } from './controllers/FeatureController';
import { POSTHOG_HOST, POSTHOG_KEY, USERFLOW_TOKEN } from './env';

if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserverPolyfill;
}
if (USERFLOW_TOKEN) {
  userflow.init(USERFLOW_TOKEN);
}

const ph = initPosthog({
  key: POSTHOG_KEY,
  host: POSTHOG_HOST,
});
syncFeatureFlags();

const container = document.getElementById('root') as HTMLElement;
const root = ReactDOMClient.createRoot(container);

root.render(
  <React.StrictMode>
    <PostHogProvider client={ph}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </PostHogProvider>
  </React.StrictMode>
);
