import * as React from 'react';

import { Icon } from '../Icon';
import { TextField } from '../TextField';
import { useForwardedRef } from '../utils';

export const PasswordField = React.forwardRef<HTMLInputElement, React.ComponentPropsWithoutRef<typeof TextField>>(
  function PasswordField(props, forwardedRef) {
    const { ...rest } = props;
    const inputRef = useForwardedRef(forwardedRef);
    const [isVisible, setIsVisible] = React.useState(false);
    return (
      <TextField
        {...rest}
        ref={inputRef}
        type={isVisible ? 'text' : 'password'}
        insertAfter={
          <div
            tabIndex={0}
            className="inline-flex h-7 w-7 cursor-pointer items-center justify-center text-xl text-text-secondary hover:text-black"
            onClick={() => {
              setIsVisible(isVisible => !isVisible);
              inputRef.current?.focus();
            }}
            onKeyDown={event => {
              if (event.key === ' ' || event.key === 'Enter') {
                setIsVisible(isVisible => !isVisible);
              }
            }}
            arial-label="Toggle password visibility"
            role="button"
          >
            <Icon icon={`ic:outline-${isVisible ? 'visibility-off' : 'visibility'}`} />
          </div>
        }
      />
    );
  }
);
