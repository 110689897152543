import { createContext, useContext, type ReactNode } from 'react';
import { type Data } from '@aether/ui/app-shared/OmniSearch';
import { getAppOrigin } from '@aether/utils/services/linking-service';

import { graphql, useQuery } from '@/graphql';
import { useAuth } from '@/utils/auth';

const OmniSearchItemsContext = createContext<Data[]>([]);

export const useOmniSearchItems = () => {
  const context = useContext(OmniSearchItemsContext);
  if (context === undefined) {
    throw new Error('useOmniSearchItems must be used within an OmniSearchItemsProvider');
  }
  return context;
};

export const OmniSearchItemsProvider = ({ children }: { children?: ReactNode }) => {
  const { isAuthenticated } = useAuth();
  const { data, loading } = useQuery(layoutOmnisearchQuery, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const items: Data[] = [
    {
      sectionLabel: 'Favourites',
      loading,
      items: [],
    },
    {
      sectionLabel: 'Portfolios',
      loading,
      items: [],
    },
    {
      sectionLabel: 'Funds',
      loading,
      items: [],
    },
  ];
  data?.strategyNames.forEach(strategy => {
    if (!strategy) return;
    if (strategy.isFavourite) items[0]?.items.push(mapStrategyToItem(strategy));
    else if (!strategy.isin) items[1]?.items.push(mapStrategyToItem(strategy));
    else items[2]?.items.push(mapStrategyToItem(strategy));
  });
  items[0].items = items[0].items.sort(
    (a, b) => new Date(b.dateCreated || '1970-01-01').getTime() - new Date(a.dateCreated || '1970-01-01').getTime()
  );
  items[1].items = items[1].items.sort(
    (a, b) => new Date(b.dateCreated || '1970-01-01').getTime() - new Date(a.dateCreated || '1970-01-01').getTime()
  );

  const filtered = isAuthenticated ? items : [items[2]];

  return <OmniSearchItemsContext.Provider value={filtered}>{children}</OmniSearchItemsContext.Provider>;
};

const mapStrategyToItem = (strategy: any) => ({
  id: strategy.isin || strategy.id,
  name: strategy.name,
  url: `${getAppOrigin()}/${strategy.isin ? `funds/${strategy.isin}` : `strategies/${strategy.id}`}`,
  dateCreated: strategy.dateCreated,
});

const layoutOmnisearchQuery = graphql(/* GraphQL */ `
  query LayoutOmnisearchQuery {
    strategyNames(input: { tags: "webapp" }) {
      id
      isFavourite
      name
      isin
      dateCreated
    }
  }
`);
