import { Button, Dialog } from '@aether/ui';

export const TermsAndConditions: React.FC<{
  isVisible: boolean;
  setVisible: (isVisible: boolean) => void;
}> = ({ isVisible, setVisible }) => {
  return (
    <Dialog.Root open={isVisible} onOpenChange={setVisible}>
      <Dialog.Content className="!max-w-5xl">
        <Dialog.Title>Terms and Conditions</Dialog.Title>
        <Dialog.Body className="flex flex-col gap-3">
          <h3>Portfolio Dragon Terms of Use</h3>
          <p>
            The Portfolio Dragon WebApp (the &quot;WebApp&quot;) may be used for informational purposes only. Your
            access to the WebApp is subject to the terms, conditions and disclaimers appearing on the WebApp (the
            &quot;Terms of use&quot;), and all applicable laws. Before using this WebApp, you should read the Terms of
            Use carefully, as well as our Cookie Policy and Privacy Policy. By accessing, browsing and/or using the
            WebApp, you accept, without limitation or qualification, the Terms of Use.
          </p>

          <p>
            Minimum age: You must be 18 years of age or older to use the Site. It is not intended for those under the
            age of 18. You represent and warrant that you are 18 years of age or older and are fully able and competent
            to enter into, and abide by, the contract created by the Terms of Use.
          </p>
          <p>
            Terms of Use cover all users: By using the WebApp, you agree to be bound by the Terms of Use, whether you
            simply browse the WebApp, are a &quot;Visitor&quot; (which means that you have registered your email address
            to receive temporary restricted access) or you are a &quot;Client User&quot; (which means that you have
            registered with Portfolio Dragon and entered into a Client Agreement with Arabesque as a professional
            client, portfolio manager or wealth manager). Each time you use the WebApp, the current version of the Terms
            of Use will apply. Please review carefully all of the WebApp&apos; Terms of Use before agreeing to them.
          </p>

          <p>
            VISITOR EXPRESSLY AGREES THAT IT IS NOT USING AND WILL NOT USE THE INFORMATION PROVIDED BY THE WEBAPP FOR
            ANYTHING OTHER THAN EVALUATING THE SITE AND FURTHER EXPRESSLY AGREES THAT IT WILL NOT MAKE ANY INVESTMENT
            DECISION(S) OR SECURITIES TRANSACTION(S) BASED ON THE INFORMATION PROVIDED.
          </p>

          <h3>Registration</h3>
          <p>
            When you register as a Visitor, you agree and understand that you are responsible for maintaining the
            confidentiality of your password which, together with your Login ID allows you to access certain portions of
            the WebApp for a period of three (3 months) beginning at the time of registration and at no cost
            (&quot;Trial Period&quot;). That Login ID and password, together with your e-mail address and any telephone
            number or other personally identifiable information you provide constitute your &quot;Registration
            Information.&quot; Any changes to your Registration Information should be provided to us promptly. By
            providing us with your e-mail address, you agree to receive all required notices electronically to that
            e-mail address for the entirety of the duration of your use of the WebApp as either a Visitor or Client
            User. It is your responsibility to update or change that address, as appropriate. Notices will be provided
            by e-mail or through a link to the appropriate page on the WebApp, accessible through any standard,
            commercially available internet browser. If you become aware of any unauthorized use of your Registration
            Information, you agree to notify Arabesque immediately.
          </p>

          <h3>Restricted Use</h3>
          <p>
            When using the WebApp, you must comply with all applicable laws, including without limitation, the rules and
            regulations of the U.S. Securities and Exchange Commission (the &quot;SEC&quot;) and UK Financial Conduct
            Authority (the &quot;FCA&quot;), any applicable foreign or domestic regulatory body, national or other
            securities exchanges, including rules against making false or misleading statements to manipulate the price
            of a security. With regard to any WebApp content owned or controlled by Arabesque, during the Trial Period
            you will not, and will not permit anyone else to:
          </p>

          <ul>
            <li>
              <p>
                store, copy, modify, distribute, publish, or resell any of the information or compile or collect any
                information as part of a database or other work;
              </p>
            </li>
            <li>
              <p>
                use any automated tool (e.g., robots, spiders) to use the WebApp or store, copy, modify, distribute,
                publish or resell any information;
              </p>
            </li>
            <li>
              <p>rent, lease, or sub-license your access to the WebApp to another person;</p>
            </li>
            <li>
              <p>use the Site or information for any purpose except for your own personal use;</p>
            </li>
            <li>
              <p>
                circumvent or disable any digital rights management, usage rules, or other security features of the
                WebApp;
              </p>
            </li>
            <li>
              <p>
                use the WebApp in a manner that threatens its integrity, performance, or availability; or remove, alter,
                or obscure any proprietary notices (including copyright notices) on any portion of the WebApp or
                information.
              </p>
            </li>
          </ul>

          <h3>Site for information only</h3>
          <p>
            During the Trial Period, you may view the information on the WebApp for informational purposes only. The
            information contained in the WebApp is subject to change without notice. The information on the WebApp: (i)
            is not, and should not be deemed, an offer to sell, or a solicitation of an offer to buy, any securities or
            any other investments in any jurisdiction; (ii) should not be used by others in connection with any sale,
            offer for sale or solicitation of an offer to buy securities or any other investments, and (iii) is not a
            recommendation or opinion for you to participate in any investment transaction. Certain investments give
            rise to substantial risk and are not suitable for all investors. Unless explicitly agreed otherwise in
            writing, Arabesque is not, by virtue of making the information on the WebApp available to you, acting as
            your advisor, investment manager, or fiduciary. Your access to the WebApp has been granted at no cost and on
            the basis that you have the capability to independently assess investment risk and will exercise independent
            judgment in evaluating investment decisions. Any decisions you make to invest in any security or other
            instrument discussed on this WebApp will be based solely on your own independent evaluation of your
            financial circumstances, investment objectives, risk tolerance, liquidity needs and any other factors that
            you deem relevant. Arabesque does not and will not assess whether any given decision is suitable for you
            based on your circumstances. At its sole discretion Arabesque may discontinue or change any product or
            service described on this WebApp at any time. Nothing on this WebApp is intended to be, and you should not
            consider anything on this WebApp to be, investment, accounting tax or legal advice. You should consult an
            attorney or tax professional regarding your specific legal or tax situation.
          </p>

          <p>
            Arabesque only provides advice on investments for those Client Users who have separately agreed a specific
            advisory service agreement with an appropriately licensed Arabesque entity (specifically, the Client
            Agreement) that governs the specific terms and conditions of such research and/or advice.
          </p>

          <h3>Limitations of information provided</h3>
          <p>
            The information available on the WebApp may not be timely, accurate or complete and should not be relied
            upon for making any decisions. Prices used to value individual positions, which are also used to calculate
            overall account valuation, are updated daily and not intra-day. Certain securities transactions, such as
            corporate actions, are updated when received and may be updated less frequently than daily. The WebApp will
            contain opinions concerning companies, securities, portfolios of securities, transactions and investment
            strategies. However, they are solely models based on an artificial intelligence machine. Arabesque reserves
            the right to delete such content in our sole and absolute discretion, and our review of any such information
            is limited to regulatory purposes. We do not guarantee that we will do so in response to a request for such
            review or otherwise. We do request, however, that if you believe that any information on the WebApp contains
            misappropriated content, or is otherwise in violation of any law, rule or regulation, that you notify us.
            Any performance information presented on the WebApp is our best efforts at forecasting one or more
            hypothetical results and is not indicative of future results. Performance results for any particular model
            investment strategy, investment or user will vary, potentially materially, based on components of
            investments, timing of investments and other discretionary factors that Portfolio Dragon does not, nor claim
            to, provide. Your use of hyperlinks on the WebApp to other websites is at your own risk. We are not
            responsible for third party sites hyperlinked from the WebApp, nor do we make any endorsements or
            warranties, express or implied, with respect to the content of third-party websites or the products or
            services offered on any third party websites, including but not limited to accuracy, completeness,
            reliability, suitability, non-infringement, merchantability or fitness for a particular purpose. We do not
            warrant or represent that any third-party website or the content thereon, is devoid of viruses or other
            contamination. We do not guarantee the authenticity of documents available on the Internet. We reserve the
            right to terminate any link at any time.
          </p>

          <h3>No Warranties</h3>
          <p>
            THE WEBAPP AND THE INFORMATION CONTAINED ON THE WEBAPP OR ACCESSED THROUGH THE WEBAPP ARE PROVIDED
            &quot;AS-IS&quot; AND &quot;AS AVAILABLE,&quot; AND ALL WARRANTIES, EXPRESS OR IMPLIED, ARE DISCLAIMED,
            INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE
            AND NON-INFRINGEMENT. ARABESQUE AND PARTICIPANTS ASSUME NO OBLIGATION, AND DISCLAIMS ANY DUTY, TO UPDATE THE
            INFORMATION. ARABESQUE MAKES NO WARRANTIES REGARDING THE TIMELINESS, ACCURACY, COMPLETENESS, RELIABILITY,
            SUITABILITY OR ADEQUACY OF THE INFORMATION, SERVICES, SOFTWARE, TEXT, GRAPHICS OR LINKS CONTAINED ON THE
            WEBAPP. ARABESQUE DOES NOT WARRANT THAT THE WEBAPP WILL OPERATE ERROR FREE, THAT THE WEBAPP WILL CALCULATE
            MATHEMATICAL AND/OR FINANCIAL CALCULATIONS CORRECTLY, THAT THE WEBAPP WILL OPERATE FREE OF &quot;BLACK
            OUT&quot; PERIODS, OR THAT THE WEBAPP AND ITS SERVERS ARE FREE OF COMPUTER VIRUSES AND OTHER HARMFUL ITEMS.
            THE ENTIRE RISK ARISING OUT OF THE USE OR PERFORMANCE OF THE WEBAPP REMAINS WITH YOU.
          </p>

          <h3>Limitation of Arabesque&rsquo;s liability</h3>
          <p>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT ARABESQUE, ITS AFFILIATES, OR THEIR
            RESPECTIVE OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES AND AGENTS BE LIABLE FOR ANY DAMAGES INCURRED BY YOU
            AS A RESULT OF YOUR USE OF THE WEBAPP OR THE INFORMATION. WITHOUT LIMITATION, ARABESQUE SHALL NOT BE LIABLE
            FOR ANY LOST PROFITS, LOST SAVINGS OR ANY CONSEQUENTIAL, INCIDENTAL, DIRECT, INDIRECT, SPECIAL, PUNITIVE OR
            OTHER DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF BUSINESS PROFITS, BUSINESS
            INTERRUPTION, LOSS OF BUSINESS INFORMATION, COSTS ASSOCIATED WITH RECREATING LOST DATA, THE COST OF ANY
            SUBSTITUTE PROGRAM OR OTHER PECUNIARY LOSS) ARISING OUT OF THIS AGREEMENT OR THE USE OF OR INABILITY TO USE
            THE WEBAPP OR THE INFORMATION, WHETHER RESULTING FROM IMPAIRED OR LOST DATA, OR ANY OTHER CAUSE, EVEN IF
            ARABESQUE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE INFORMATION AVAILABLE OR OBTAINED THROUGH
            THE WEBAPP MAY BE SUBJECT TO ADDITIONAL DISCLAIMERS AND LIMITATIONS CONTAINED THEREIN.
          </p>

          <h3>User Conduct</h3>
          <p>
            You understand that all information, whether publicly or privately transmitted, is the sole responsibility
            of the participant from whom such information originated. This means that you, and not us, are entirely
            responsible for all information that you upload, email, transmit or otherwise make available to us and/or
            the WebApp. You agree to not use the WebApp to: a. upload, email, transmit or otherwise make available any
            information that is unlawful harmful, threatening, abusive, harassing, tortuous, defamatory, vulgar,
            obscene, libelous, invasive of another’s privacy, hateful, or racially, ethnically or otherwise
            objectionable; b. impersonate any person or entity, including, but not limited to, a WebApp official, guide
            or host, or falsely state or otherwise misrepresent your affiliation with a person or entity; c. forge
            headers or otherwise manipulate identifiers in order to disguise the origin of any information transmitted
            by or to you; d. upload, email, transmit or otherwise make available any information that you do not have a
            right to make available under any law or under contractual or fiduciary relationships (such as inside
            information, proprietary and confidential information learned or disclosed as part of employment
            relationships or under nondisclosure agreements); e. upload, email, transmit or otherwise make available any
            information that infringes any patent, trademark, trade secret, copyright or other proprietary rights of any
            party; f. upload, email, transmit or otherwise make available any material that contains software viruses or
            any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any
            computer software or hardware or telecommunications equipment; and/or g. intentionally or unintentionally
            violate any applicable local, state, national or international law, including, but not limited to,
            regulations promulgated by the FCA / SEC, any rules of any national or other securities exchange, including,
            without limitation, the London Stock Exchange, New York Stock Exchange, NYSE Amex, or the Nasdaq, and any
            regulations having the force of law. You acknowledge the following: (i) we do not pre-screen or review
            participant-submitted information (except where explicitly agreed otherwise in writing in a Client Agreement
            ), but that we shall have the right (but not the obligation) in our sole discretion to pre-screen, refuse,
            move or remove any such information, (ii) we may monitor and record activity on the WebApp for any reason or
            for no reason, (iii) we may investigate any complaint or reported violation of our policies, and (iv) we may
            report any activity that we suspect may violate any law or regulation to regulators, law enforcement
            officials or other persons or entities that we deem appropriate.
          </p>

          <h3>Your indemnification of Arabesque</h3>
          <p>
            You agree to indemnify, defend and hold Arabesque harmless from and against any and all claims, actions,
            liabilities, losses, costs, damages or suits (including, but not limited to, attorneys&apos; fees) caused by
            or arising from your use of the WebApp, information provided to us by you, your violation of any Terms of
            Use, or your or any user of your account ́s infringement of any intellectual property or other rights of any
            person or entity.
          </p>

          <h3>Content and Activity Submissions</h3>
          <p>
            You may choose to submit content to us. By doing so, you are licensing to Arabesque any information, data,
            materials or other content you provide through or to the WebApp. By submitting content, you agree that you
            are the owner of such content, have the right to submit such content, and that, without any particular time
            limit, and without the payment of any fees Arabesque may use, modify, display, distribute and create new
            material using such content. We shall act as provided in our Privacy Policy, or as otherwise required by
            law, with respect to the disclosure of Registration Information (e.g., name, address, email) submitted to us
            by other means. If you would like to protect any information submitted by you, please submit it to us under
            the bolded statement that the following information is personal and confidential.
          </p>

          <h3>Proprietary rights.</h3>
          <p>
            You are permitted to use content delivered to you through the WebApp in accordance with the Terms of Use.
            You may not publish, copy, reproduce, distribute, or create derivative works from this content. Further, you
            agree not to reverse engineer or reverse compile any of the service technology, including but not limited
            to, any Java applets associated with the service.
          </p>

          <h3>Content you provide. </h3>
          <p>
            You are licensing to Arabesque, any information, data, materials or other content (collectively, Content)
            you provide through or to the WebApp service. Arabesque may use, modify, display, distribute and create new
            material using such Content to provide the service to you. By submitting Content, you automatically agree,
            or promise that the owner of such Content has expressly agreed that, without any particular time limit, and
            without the payment of any fees, Arabesque may use the Content for the purposes set out above. The laws of
            certain countries and states do not allow limitations on implied warranties or the exclusion or limitation
            of certain damages. If these laws apply to you, some or all of the above disclaimers, exclusions, or
            limitations may not apply to you, and you might have additional rights.
          </p>

          <h3>DISCLAIMER OF WARRANTIES</h3>
          <p>
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT: YOUR USE OF THE WEBAPP AND ALL INFORMATION, PRODUCTS AND OTHER
            CONTENT (INCLUDING THAT OF THIRD PARTIES) INCLUDED IN OR ACCESSIBLE FROM THE WEBAPP IS AT YOUR SOLE RISK.
            THE SERVICE IS PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS. ARABESQUE EXPRESSLY
            DISCLAIMS ALL WARRANTIES OF ANY KIND AS TO THE SERVICE AND ALL INFORMATION, PRODUCTS AND OTHER CONTENT
            (INCLUDING THAT OF THIRD PARTIES) INCLUDED IN OR ACCESSIBLE FROM THE WEBAPP, WHETHER EXPRESS OR IMPLIED,
            INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE
            AND NON-INFRINGEMENT. ARABESQUE MAKES NO WARRANTY THAT (i) THE SERVICE WILL MEET YOUR REQUIREMENTS, (ii) THE
            SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (iii) THE RESULTS THAT MAY BE OBTAINED FROM
            THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE, (iv) THE QUALITY OF ANY PRODUCTS, SERVICES,
            INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS,
            OR (V) ANY ERRORS IN THE TECHNOLOGY WILL BE CORRECTED. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH
            THE USE OF THE SERVICE IS DONE AT YOUR OWN DISCRETION AND RISK AND YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE
            TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL. NO ADVICE OR
            INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM ARABESQUE THROUGH OR FROM THE SERVICE OR WEBAPP
            WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THESE TERMS. LIMITATION OF LIABILITY. YOU AGREE THAT
            NEITHER ARABESQUE NOR ANY OF ITS AFFILIATES, ACCOUNT PROVIDERS OR ANY OF THEIR AFFILIATES WILL BE LIABLE FOR
            ANY HARMS, WHICH LAWYERS AND COURTS OFTEN CALL DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
            EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER
            INTANGIBLE LOSSES, EVEN IF ARABESQUE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, RESULTING FROM:
            (i) THE USE OR THE INABILITY TO USE THE SERVICE; (ii) THE COST OF GETTING SUBSTITUTE GOODS AND SERVICES,
            (iii) ANY PRODUCTS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS
            ENTERED INTO, THROUGH OR FROM THE SERVICE; (iv) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS
            OR DATA; (v) STATEMENTS OR CONDUCT OF ANYONE ON THE SERVICE; (vi) THE USE, INABILITY TO USE, UNAUTHORIZED
            USE, PERFORMANCE OR NON-PERFORMANCE OF ANY THIRD PARTY ACCOUNT PROVIDER WEBSITE, EVEN IF THE PROVIDER HAS
            BEEN ADVISED PREVIOUSLY OF THE POSSIBILITY OF SUCH DAMAGES; OR (vii) ANY OTHER MATTER RELATING TO THE
            SERVICE OR WEBAPP.
          </p>

          <h3>Termination</h3>
          <p>
            Arabesque reserves the right to, and may, terminate or temporarily suspend your access to all or any part of
            the WebApp, without notice, for conduct that Arabesque believes violates these Terms of Use or any of its
            policies or guidelines, or for any other reason in its sole discretion.
          </p>

          <h3>Governing law&nbsp;</h3>
          <p>
            This agreement and any dispute or claim arising out of or in connection with it or its subject matter
            (including non-contractual disputes or claims) shall be governed by and construed in accordance with the
            laws of England and Wales. And you irrevocably agree that the courts of England and Wales shall have
            exclusive jurisdiction to settle any dispute or claim that arises out of or in connection with this
            agreement or its subject matter (including non-contractual disputes or claims).
          </p>

          <h3>Trademarks; proprietary rights</h3>
          <p>
            The WebApp contains copyrighted material, trademarks and other proprietary information, including text,
            graphics and software. Arabesque is the owner of the copyright in the WebApp, as well as the copyright in
            any information original to Arabesque. Each participant, or other third-party provider of information, owns
            the copyright in the information original to it. Except as permitted under copyright law, you shall not
            copy, redistribute, publish or commercially exploit the WebApp, any portion of the WebApp, the Service, or
            any of the information without the express written consent of the respective owner(s) thereof. Arabesque
            &apos; trademarks (including any service marks) and logos are the property of Arabesque. All other
            trademarks appearing on the WebApp are the property of their respective owners. Nothing contained on the
            WebApp should be construed as granting by implication, or otherwise, any license or right to use the
            trademarks or logos appearing on the WebApp without the express written consent of the owner.
          </p>

          <h3>Third Party Provider Restrictions</h3>
          <p>
            Third party data and/or service providers may impose additional restrictions or obligations on the use of
            their information, data or services (&quot;Third Party Data&quot;) and/or may change such restrictions.
            These restrictions or obligations may include requiring Arabesque to report your usage of such Third Party
            Data to the Third Party Provider. Customer agrees that for the duration of this Agreement and any perpetual
            licence granted hereunder, it shall have the appropriate licence to obtain, redistribute and/or use any
            applicable Third Party Data (where required). Third party providers may have the right to require Arabesque
            to restrict, suspend or terminate your access to Third Party Data. If Arabesque takes any such action, it
            will not be liable for any resulting damages you may suffer. For the avoidance of doubt, Arabesque is never
            obliged to provide any Third Party Data.
          </p>

          <h3>Additional Terms for Third Party Data</h3>
          <p>
            You agree and acknowledge that Third Party Data is and shall remain the valuable intellectual property owned
            by, or licensed to, the applicable third party provider and no proprietary rights in Third Party Data is
            transferred to you. To the maximum extent permitted by applicable law, neither any third party data or
            service providers nor any of its affiliates makes any warranty as to the accuracy, adequacy or completeness
            of the Third Party Data, including without any limitation, any warranty of satisfactory quality or fitness
            for a particular purpose or use. Neither any third party data Provider nor its affiliate shall have any
            liability for any error or omission in any Third Party Data and no third party data provider shall be liable
            for any damages resulting therefrom.
          </p>

          <h3>General</h3>
          <p>These Terms of Use are applicable only to the WebApp and the use thereof in the manner set forth here.</p>
          <Button onClick={() => setVisible(false)}>Close</Button>
        </Dialog.Body>
        <Dialog.Close />
      </Dialog.Content>
    </Dialog.Root>
  );
};
