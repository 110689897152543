import { useState } from 'react';
import { type AuthUserFragment } from '@aether/client-graphql/generated/graphql';
import { Alert, cn } from '@aether/ui';
import { Footer } from '@aether/ui/app-shared/Footer';
import { ButtonNew } from '@aether/ui/ButtonNew';
import { formatDateTimeForDisplay } from '@aether/utils/date-helpers';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Link, Outlet } from 'react-router-dom';

import { AuthLink } from '@/pages/Auth/components/AuthLink';
import { useAuth } from '@/utils/auth';

import { OmniSearch } from '../OmniSearch/OmniSearch';
import { OmniSearchItemsProvider } from '../OmniSearch/OmniSearchItemsProvider';
import { PageLoader } from '../PageLoader/PageLoader';
import SideNavBar from './SideNavBar';

dayjs.extend(relativeTime);
const SIDEBAR_STATE_KEY = 'isSideNavBarOpen';

export default function Layout() {
  const { isAuthenticated, user } = useAuth();
  const [isOpen, setIsOpen] = useState<boolean>(['true', null].includes(localStorage.getItem(SIDEBAR_STATE_KEY)));

  const handleToggle = () => {
    const newState = !isOpen;
    setIsOpen(newState);
    localStorage.setItem(SIDEBAR_STATE_KEY, String(newState));
  };

  const displayTrialBanner = Boolean(user && user.trialStatus && user.trialStatus !== 'none');

  return (
    <OmniSearchItemsProvider>
      <PageLoader />
      <div className="flex">
        <SideNavBar isOpen={isOpen} handleToggle={handleToggle} />
        <main
          className={cn(
            'grid w-full max-w-full grid-cols-1 bg-white',
            !isAuthenticated || displayTrialBanner ? 'grid-rows-[auto_auto_1fr]' : 'grid-rows-[auto_1fr]'
          )}
        >
          {user && <TrialStatusIndicator user={user} />}
          {!isAuthenticated && (
            <div className="centered-page sticky top-0 z-20 grid w-full grid-cols-[1fr_auto] gap-4 border-b border-gray-300 bg-white py-3">
              <OmniSearch />
              <div className="shrink-0 space-x-4">
                <ButtonNew variant="primary" iconBefore="ic:outline-add" asChild>
                  <AuthLink to="/auth/register">Create Account</AuthLink>
                </ButtonNew>
                <ButtonNew variant="secondary" iconBefore="ic:outline-edit" asChild>
                  <AuthLink to="/auth/login">Sign In</AuthLink>
                </ButtonNew>
              </div>
            </div>
          )}
          <Outlet />
          <Footer isAuthenticated={isAuthenticated} className="mb-0 mt-auto border-gray-300 bg-gray-100 p-2 px-3" />
        </main>
      </div>
    </OmniSearchItemsProvider>
  );
}

function TrialStatusIndicator(props: { user: AuthUserFragment }) {
  const { trialStatus, trialExpiresAt } = props.user;
  const trialExpiresDate = dayjs(trialExpiresAt);
  const trialDays = trialExpiresDate.diff(dayjs(), 'day');
  switch (trialStatus) {
    case 'active':
      return (
        <div className="px-4 pt-4">
          <Alert level="success" className="border-[#0D9453] bg-[#3D6C4D] [&>*]:text-white">
            <p>
              Your trial expires{' '}
              <time
                dateTime={dayjs(trialExpiresAt).format('YYYY-MM-DDTHH:MM')}
                title={formatDateTimeForDisplay(trialExpiresAt!)}
              >
                {
                  /* `.fromNow()` can round to months but we only want days or below  */
                  trialDays > 1 ? `in ${trialDays} days` : dayjs(trialExpiresAt).fromNow()
                }
              </time>
              . Please{' '}
              <Link to="/account/plan" className="underline">
                subscribe here
              </Link>
              .
            </p>
          </Alert>
        </div>
      );
    case 'expired':
      return (
        <div className="px-4 pt-4">
          <Alert level="warning">
            <p>
              Your trial has expired. Please{' '}
              <Link to="/account/plan" className="underline">
                subscribe here
              </Link>
              .
            </p>
          </Alert>
        </div>
      );
    case 'pendingPayment':
      return (
        <div className="px-4 pt-4">
          <Alert level="info">
            <p>
              Your subscription is{' '}
              <Link to="/account/plan" className="underline">
                pending confirmation
              </Link>
              . You can continue using the platform in the meantime.
            </p>
          </Alert>
        </div>
      );
    default:
      return null;
  }
}
