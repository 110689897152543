import { useEffect } from 'react';
import { captureException } from '@sentry/react';
import { useRouteError } from 'react-router-dom';

export function RouterError() {
  const error = useRouteError();
  useEffect(() => {
    captureException(error);
  }, [error]);
  return (
    <div className="flex w-full flex-1 items-center justify-center">
      <div className="rounded-3xl p-10 shadow-card">
        <h1 className="text-2xl">An error occurred</h1>
        <h3 className="text-base">An error occurred while trying to load this page.</h3>
      </div>
    </div>
  );
}
