import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

import { useAbilityContext, useAuth } from '@/utils/auth';

export const Home = () => {
  const { isAuthenticated, fetchingUser } = useAuth();
  const ability = useAbilityContext();
  const location = useLocation();
  const [params] = useSearchParams();
  const redirectTo =
    params.get('redirectTo') ?? encodeURIComponent(location.pathname + location.search + location.hash);

  // wait for the user and the feature flag before redirecting
  if (fetchingUser) return;

  if (!isAuthenticated) {
    window.location.replace(`/auth/login?redirectTo=${redirectTo}`);
  } else return <Navigate replace to={ability.can('read', 'DashboardPage') ? '/dashboard/all' : '/welcome'} />;

  return null;
};
