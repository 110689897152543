import { ApolloLink } from '@apollo/client';

class QueriesProgress extends EventTarget {
  private queries = new Map<string, boolean>();

  constructor() {
    super();
  }

  private getProgress() {
    return (
      [...this.queries.values()].reduce((acc, current) => {
        if (current) acc++;
        return acc;
      }, 0) / this.queries.size
    );
  }

  startQuery(queryName: string) {
    this.queries.set(queryName, false);
    this.dispatchEvent(new CustomEvent('progress', { detail: this.getProgress() }));
  }

  completeQuery(queryName: string) {
    this.queries.set(queryName, true);
    this.dispatchEvent(new CustomEvent('progress', { detail: this.getProgress() }));
  }

  removeEventListener(
    type: string,
    callback: EventListenerOrEventListenerObject | null,
    options?: boolean | EventListenerOptions | undefined
  ): void {
    // The only subscriber to the `progress` event at the moment is the
    // PageLoader component. At the moment it's safe to clear the queries list
    // when the (only) listener is removed. If at some point we want to subscribe more
    // event listeners, this logic needs to be refactored.
    this.queries.clear();
    super.removeEventListener(type, callback, options);
  }
}

export const queriesProgress = new QueriesProgress();

export const queriesProgressLink = new ApolloLink((operation, forward) => {
  queriesProgress.startQuery(operation.operationName);

  return forward(operation).map(data => {
    queriesProgress.completeQuery(operation.operationName);
    return data;
  });
});
