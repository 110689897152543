import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { Icon as IconComponent } from '../Icon';
import { cn } from '../utils';

export type InputWrapperProps = React.ComponentProps<'div'> & { asChild?: boolean };

function Wrapper(props: InputWrapperProps) {
  const { asChild, className, ...rest } = props;
  const Component = (asChild ? Slot : 'div') as 'div';
  return <Component {...rest} className={cn('flex flex-col gap-1', className)} />;
}

// Label
const labelStyles = cva('font-normal text-text-primary', {
  variants: {
    density: {
      default: 'text-sm',
      small: 'text-xs',
      dense: 'text-xs',
    },
  },
  defaultVariants: {
    density: 'default',
  },
});

type InputLabelProps = React.ComponentProps<'label'> & VariantProps<typeof labelStyles> & { asChild?: boolean };

function Label(props: InputLabelProps) {
  const { asChild, density, className, ...rest } = props;
  const Component = (asChild ? Slot : 'label') as 'label';
  return <Component {...rest} className={cn(labelStyles({ density }), className)} />;
}

// Description
type InputDescriptionProps = React.ComponentProps<'div'> & { asChild?: boolean };

function Description(props: InputDescriptionProps) {
  const { asChild, className, ...rest } = props;
  const Component = (asChild ? Slot : 'div') as 'div';
  return <Component {...rest} className={cn('text-xs text-grey-ds-700', className)} />;
}

type InputErrorMessageProps = React.ComponentProps<'div'> & { asChild?: boolean };

// ErrorMessage
function ErrorMessage(props: InputErrorMessageProps) {
  const { asChild, className, ...rest } = props;
  const Component = (asChild ? Slot : 'div') as 'div';
  return <Component {...rest} className={cn('text-sm text-error-500', className)} />;
}

type InputFieldGroupProps = React.ComponentProps<'div'> & VariantProps<typeof inputStyles>;

// Icon

const iconStyles = cva('text-text-secondary group-focus-within:text-text-secondary', {
  variants: {
    density: {
      default: 'text-base/4',
      small: 'text-base/4',
      dense: 'text-base/4',
    },
    error: {
      true: 'text-error-500',
    },
  },
  defaultVariants: {
    density: 'default',
    error: false,
  },
});

function Icon(props: React.ComponentProps<typeof IconComponent> & VariantProps<typeof iconStyles>) {
  const { error, className, ...rest } = props;
  return <IconComponent {...rest} className={cn(iconStyles({ error }), className)} />;
}

// Group

function FieldGroup(props: InputFieldGroupProps) {
  const { className, variant, density, error, ...rest } = props;
  return (
    <div
      className={cn(
        inputStyles({ variant, density, error }),
        'group flex w-full max-w-full items-center px-1 text-base focus-within:border-border-dark',
        '[&_.ac-field]:flex-1 [&_.ac-field]:border-transparent [&_.ac-field]:bg-transparent [&_.ac-field]:px-1 [&_.ac-field]:shadow-none',
        className
      )}
      {...rest}
    />
  );
}

type InputFieldProps = React.ComponentProps<'input'> & VariantProps<typeof inputStyles>;

// Field
const Field = React.forwardRef<HTMLInputElement, InputFieldProps>(function (props, forwardedRef) {
  const { className, variant, density, error, ...rest } = props;
  return (
    <input
      ref={forwardedRef}
      {...rest}
      className={cn('ac-field min-w-[64px] outline-none', inputStyles({ variant, density, error }), className)}
    />
  );
});

// module exports
export const Input = { Wrapper, Label, Description, ErrorMessage, FieldGroup, Field, Icon };

export const inputStyles = cva('box-border rounded border bg-white text-text-primary', {
  variants: {
    variant: {
      default: 'border-border-medium placeholder-text-secondary focus-visible:border-border-dark',
      shadowed: 'border-transparent shadow-card',
      flat: 'border-none bg-transparent',
      unstyled: '',
    },
    density: {
      default: 'h-9 px-2 text-sm leading-4',
      small: 'h-8 px-2 text-xs',
      dense: 'h-6 px-2 text-xs',
    },
    error: {
      true: 'border-error-500',
    },
  },
  defaultVariants: {
    variant: 'default',
    density: 'default',
    error: false,
  },
});
