/* eslint-disable */
export default [
  {
    "code": "AE",
    "name": "United Arab Emirates",
    "regionCode": "middle_east",
    "regionName": "Middle East",
    "market": "emerging",
    "currency": "AED"
  },
  {
    "code": "AR",
    "name": "Argentina",
    "regionCode": "lat_am",
    "regionName": "Latin America",
    "market": "frontier",
    "currency": "ARS"
  },
  {
    "code": "AT",
    "name": "Austria",
    "regionCode": "europe",
    "regionName": "Europe",
    "market": "developed",
    "currency": "EUR"
  },
  {
    "code": "AU",
    "name": "Australia",
    "regionCode": "pacific",
    "regionName": "Pacific",
    "market": "developed",
    "currency": "AUD"
  },
  {
    "code": "BE",
    "name": "Belgium",
    "regionCode": "europe",
    "regionName": "Europe",
    "market": "developed",
    "currency": "EUR"
  },
  {
    "code": "BH",
    "name": "Bahrain",
    "regionCode": "middle_east",
    "regionName": "Middle East",
    "market": "frontier",
    "currency": "BHD"
  },
  {
    "code": "BR",
    "name": "Brazil",
    "regionCode": "lat_am",
    "regionName": "Latin America",
    "market": "emerging",
    "currency": "BRL"
  },
  {
    "code": "CA",
    "name": "Canada",
    "regionCode": "north_america",
    "regionName": "North America",
    "market": "developed",
    "currency": "CAD"
  },
  {
    "code": "CH",
    "name": "Switzerland",
    "regionCode": "europe",
    "regionName": "Europe",
    "market": "developed",
    "currency": "CHF"
  },
  {
    "code": "CL",
    "name": "Chile",
    "regionCode": "lat_am",
    "regionName": "Latin America",
    "market": "emerging",
    "currency": "CLP"
  },
  {
    "code": "CN",
    "name": "China",
    "regionCode": "asia",
    "regionName": "Asia",
    "market": "emerging",
    "currency": "CNY"
  },
  {
    "code": "CO",
    "name": "Colombia",
    "regionCode": "lat_am",
    "regionName": "Latin America",
    "market": "emerging",
    "currency": "COP"
  },
  {
    "code": "CY",
    "name": "Cyprus",
    "regionCode": "europe",
    "regionName": "Europe",
    "market": "frontier",
    "currency": "EUR"
  },
  {
    "code": "CZ",
    "name": "Czech Republic",
    "regionCode": "europe",
    "regionName": "Europe",
    "market": "emerging",
    "currency": "CZK"
  },
  {
    "code": "DE",
    "name": "Germany",
    "regionCode": "europe",
    "regionName": "Europe",
    "market": "developed",
    "currency": "EUR"
  },
  {
    "code": "DK",
    "name": "Denmark",
    "regionCode": "europe",
    "regionName": "Europe",
    "market": "developed",
    "currency": "DKK"
  },
  {
    "code": "EG",
    "name": "Egypt",
    "regionCode": "middle_east",
    "regionName": "Middle East",
    "market": "emerging",
    "currency": "EGP"
  },
  {
    "code": "ES",
    "name": "Spain",
    "regionCode": "europe",
    "regionName": "Europe",
    "market": "developed",
    "currency": "EUR"
  },
  {
    "code": "FI",
    "name": "Finland",
    "regionCode": "europe",
    "regionName": "Europe",
    "market": "developed",
    "currency": "EUR"
  },
  {
    "code": "FR",
    "name": "France",
    "regionCode": "europe",
    "regionName": "Europe",
    "market": "developed",
    "currency": "EUR"
  },
  {
    "code": "GB",
    "name": "United Kingdom",
    "regionCode": "europe",
    "regionName": "Europe",
    "market": "developed",
    "currency": "GBP"
  },
  {
    "code": "GR",
    "name": "Greece",
    "regionCode": "europe",
    "regionName": "Europe",
    "market": "emerging",
    "currency": "EUR"
  },
  {
    "code": "HK",
    "name": "Hong Kong",
    "regionCode": "asia",
    "regionName": "Asia",
    "market": "developed",
    "currency": "HKD"
  },
  {
    "code": "HU",
    "name": "Hungary",
    "regionCode": "europe",
    "regionName": "Europe",
    "market": "emerging",
    "currency": "HUF"
  },
  {
    "code": "ID",
    "name": "Indonesia",
    "regionCode": "asia",
    "regionName": "Asia",
    "market": "emerging",
    "currency": "IDR"
  },
  {
    "code": "IE",
    "name": "Ireland",
    "regionCode": "europe",
    "regionName": "Europe",
    "market": "developed",
    "currency": "EUR"
  },
  {
    "code": "IL",
    "name": "Israel",
    "regionCode": "middle_east",
    "regionName": "Middle East",
    "market": "developed",
    "currency": "ILS"
  },
  {
    "code": "IN",
    "name": "India",
    "regionCode": "asia",
    "regionName": "Asia",
    "market": "emerging",
    "currency": "INR"
  },
  {
    "code": "IS",
    "name": "Iceland",
    "regionCode": "europe",
    "regionName": "Europe",
    "market": "developed",
    "currency": "ISK"
  },
  {
    "code": "IT",
    "name": "Italy",
    "regionCode": "europe",
    "regionName": "Europe",
    "market": "developed",
    "currency": "EUR"
  },
  {
    "code": "JO",
    "name": "Jordan",
    "regionCode": "middle_east",
    "regionName": "Middle East",
    "market": "frontier",
    "currency": "JOD"
  },
  {
    "code": "JP",
    "name": "Japan",
    "regionCode": "asia",
    "regionName": "Asia",
    "market": "developed",
    "currency": "JPY"
  },
  {
    "code": "KR",
    "name": "South Korea",
    "regionCode": "asia",
    "regionName": "Asia",
    "market": "developed",
    "currency": "KRW"
  },
  {
    "code": "KW",
    "name": "Kuwait",
    "regionCode": "middle_east",
    "regionName": "Middle East",
    "market": "frontier",
    "currency": "KWD"
  },
  {
    "code": "LU",
    "name": "Luxembourg",
    "regionCode": "europe",
    "regionName": "Europe",
    "market": "developed",
    "currency": "EUR"
  },
  {
    "code": "MA",
    "name": "Morocco",
    "regionCode": "africa",
    "regionName": "Africa",
    "market": "emerging",
    "currency": "MAD"
  },
  {
    "code": "MX",
    "name": "Mexico",
    "regionCode": "north_america",
    "regionName": "North America",
    "market": "emerging",
    "currency": "MXN"
  },
  {
    "code": "MY",
    "name": "Malaysia",
    "regionCode": "asia",
    "regionName": "Asia",
    "market": "emerging",
    "currency": "MYR"
  },
  {
    "code": "NG",
    "name": "Nigeria",
    "regionCode": "africa",
    "regionName": "Africa",
    "market": "frontier",
    "currency": "NGN"
  },
  {
    "code": "NL",
    "name": "Netherlands",
    "regionCode": "europe",
    "regionName": "Europe",
    "market": "developed",
    "currency": "EUR"
  },
  {
    "code": "NO",
    "name": "Norway",
    "regionCode": "europe",
    "regionName": "Europe",
    "market": "developed",
    "currency": "NOK"
  },
  {
    "code": "NZ",
    "name": "New Zealand",
    "regionCode": "pacific",
    "regionName": "Pacific",
    "market": "developed",
    "currency": "NZD"
  },
  {
    "code": "OM",
    "name": "Oman",
    "regionCode": "middle_east",
    "regionName": "Middle East",
    "market": "other",
    "currency": "OMR"
  },
  {
    "code": "PE",
    "name": "Peru",
    "regionCode": "lat_am",
    "regionName": "Latin America",
    "market": "emerging",
    "currency": "PEN"
  },
  {
    "code": "PH",
    "name": "Philippines",
    "regionCode": "asia",
    "regionName": "Asia",
    "market": "emerging",
    "currency": "PHP"
  },
  {
    "code": "PK",
    "name": "Pakistan",
    "regionCode": "asia",
    "regionName": "Asia",
    "market": "frontier",
    "currency": "PKR"
  },
  {
    "code": "PL",
    "name": "Poland",
    "regionCode": "europe",
    "regionName": "Europe",
    "market": "emerging",
    "currency": "PLN"
  },
  {
    "code": "PT",
    "name": "Portugal",
    "regionCode": "europe",
    "regionName": "Europe",
    "market": "developed",
    "currency": "EUR"
  },
  {
    "code": "QA",
    "name": "Qatar",
    "regionCode": "middle_east",
    "regionName": "Middle East",
    "market": "frontier",
    "currency": "QAR"
  },
  {
    "code": "RO",
    "name": "Romania",
    "regionCode": "europe",
    "regionName": "Europe",
    "market": "emerging",
    "currency": "RON"
  },
  {
    "code": "RU",
    "name": "Russian Federation",
    "regionCode": "europe",
    "regionName": "Europe",
    "market": "frontier",
    "currency": "RUB"
  },
  {
    "code": "SA",
    "name": "Saudi Arabia",
    "regionCode": "middle_east",
    "regionName": "Middle East",
    "market": "frontier",
    "currency": "SAR"
  },
  {
    "code": "SE",
    "name": "Sweden",
    "regionCode": "europe",
    "regionName": "Europe",
    "market": "developed",
    "currency": "SEK"
  },
  {
    "code": "SG",
    "name": "Singapore",
    "regionCode": "asia",
    "regionName": "Asia",
    "market": "developed",
    "currency": "SGD"
  },
  {
    "code": "TH",
    "name": "Thailand",
    "regionCode": "asia",
    "regionName": "Asia",
    "market": "emerging",
    "currency": "THB"
  },
  {
    "code": "TR",
    "name": "Turkey",
    "regionCode": "europe",
    "regionName": "Europe",
    "market": "emerging",
    "currency": "TRY"
  },
  {
    "code": "TW",
    "name": "Taiwan",
    "regionCode": "asia",
    "regionName": "Asia",
    "market": "emerging",
    "currency": "TWD"
  },
  {
    "code": "US",
    "name": "United States",
    "regionCode": "north_america",
    "regionName": "North America",
    "market": "developed",
    "currency": "USD"
  },
  {
    "code": "VN",
    "name": "Vietnam",
    "regionCode": "asia",
    "regionName": "Asia",
    "market": "frontier",
    "currency": "VND"
  },
  {
    "code": "VU",
    "name": "Vanuatu",
    "regionCode": "pacific",
    "regionName": "Pacific",
    "market": "other",
    "currency": "VUV"
  },
  {
    "code": "ZA",
    "name": "South Africa",
    "regionCode": "africa",
    "regionName": "Africa",
    "market": "emerging",
    "currency": "ZAR"
  }
] as const;
  