import { useEffect } from 'react';

export const PageLoaderController = () => {
  useEffect(() => {
    const loader = document.getElementById('page-loader');
    if (loader) {
      loader.style.opacity = '0.1';
      setTimeout(() => loader.remove(), 500);
    }
  });
  return null;
};
