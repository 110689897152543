import { useState } from 'react';
import { Button } from '@aether/ui/Button';
import { getWebsiteOrigin } from '@aether/utils/services/linking-service';

import { getCookieConsent, setCookieConsent } from './cookie';
import { optInTracking } from './tracker';

export function ConsentBanner() {
  const [consentRecorded, setConsentRecorded] = useState(Boolean(getCookieConsent()));
  const getDragonURL = getWebsiteOrigin();

  if (consentRecorded) return null;

  return (
    <div className="fixed bottom-0 z-50 flex w-full justify-center bg-portfolio-400 p-3 text-white print:hidden">
      <div className="flex flex-wrap items-center justify-center gap-4 sm:!flex-nowrap">
        <p className="text-center">
          This website uses cookies to provide you with the best browsing experience.{' '}
          <a
            href={`${getDragonURL}/cookie-policy`}
            target="_blank"
            className="whitespace-nowrap font-medium underline underline-offset-4"
          >
            Find out more
          </a>
          .
        </p>
        <Button
          onClick={() => {
            setCookieConsent('full');
            optInTracking();
            setConsentRecorded(true);
          }}
          className="sm:flex-0 flex-1"
        >
          Accept All
        </Button>
        <Button
          onClick={() => {
            setCookieConsent('min');
            setConsentRecorded(true);
          }}
          variantColor="accent"
          className="sm:flex-0 flex-1"
        >
          Strictly Necessary Only
        </Button>
      </div>
    </div>
  );
}
