import { AlertDialog } from '@aether/ui';
import { ButtonNew } from '@aether/ui/ButtonNew';
import { type AfterAuth } from '@aether/validation';

import { AuthLink } from '@/pages/Auth/components/AuthLink';
import { useAuth } from '@/utils/auth';

interface RequiredAuthProps extends React.ComponentProps<typeof AlertDialog.Trigger> {
  afterAuth: AfterAuth;
}

export const RequiresAuth = ({ children, afterAuth, ...rest }: RequiredAuthProps) => {
  const { isAuthenticated } = useAuth();
  if (isAuthenticated) return children;
  return (
    <AlertDialog.Root>
      <AlertDialog.Trigger asChild {...rest}>
        {children}
      </AlertDialog.Trigger>
      <AlertDialog.Content overlayOpacity="bg-opacity-50">
        <AlertDialog.Title className="text-lg font-medium">Authentication required</AlertDialog.Title>
        <AlertDialog.Body>
          <div className="flex flex-col gap-4">
            <div className="min-h-[100px]">
              <AlertDialog.Description>You need to be authenticated to perform this action.</AlertDialog.Description>
            </div>
            <div className="flex justify-end gap-4">
              <AlertDialog.Action>
                <ButtonNew iconBefore="ic:outline-add" asChild>
                  <AuthLink to="/auth/register" state={{ afterAuth }}>
                    Create Account
                  </AuthLink>
                </ButtonNew>
              </AlertDialog.Action>
              <AlertDialog.Action>
                <ButtonNew iconBefore="ic:outline-edit" variant="secondary">
                  <AuthLink to="/auth/login" state={{ afterAuth }}>
                    Sign In
                  </AuthLink>
                </ButtonNew>
              </AlertDialog.Action>
              <AlertDialog.Cancel variant="unstyled" asChild>
                <ButtonNew variant="secondary">Cancel</ButtonNew>
              </AlertDialog.Cancel>
            </div>
          </div>
        </AlertDialog.Body>
        <AlertDialog.Cancel />
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
