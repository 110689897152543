export const GRAPHQL_ENDPOINT =
  process.env.NX_GRAPHQL_API_URL || `${location.protocol}//${location.hostname.replace('app', 'bff')}/graphql`;
export const REST_ENDPOINT =
  process.env.NX_GRAPHQL_API_URL?.replace('/graphql', '/rest') ||
  `${location.protocol}//${location.hostname.replace('app', 'bff')}/rest`;
export const PROXY_ENDPOINT =
  process.env.NX_BFF_PROXY_URL || `${location.protocol}//${location.hostname.replace('app', 'bff-proxy')}`;

export const POSTHOG_KEY = process.env.NX_POSTHOG_KEY;
export const POSTHOG_HOST =
  process.env.NX_POSTHOG_HOST || `${location.protocol}//${location.hostname.replace('app', 'hedgehog')}`;

export const USERFLOW_TOKEN = process.env.NX_USERFLOW_TOKEN;
