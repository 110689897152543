import { useEffect, useState } from 'react';
import { Alert, Button, Card, Heading } from '@aether/ui';
import { useNavigate } from 'react-router-dom';

import { PageSeoTitle } from '@/components/PageSeoTitle';
import { useAuth } from '@/utils/auth';

const DELAY = 2000;

export const EmailVerification = () => {
  const navigate = useNavigate();
  const { user, signOut, sendVerificationEmail } = useAuth();
  const [emailSent, setEmailSent] = useState(false);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    if (!user || user.role === 'public') {
      navigate('/auth/login', { replace: true });
    } else if (user.emailVerified) {
      navigate('/', { replace: true });
    }
  }, [user, navigate]);

  useEffect(() => {
    if (!emailSent) {
      return;
    }
    const handle = setTimeout(() => {
      setEmailSent(false);
    }, DELAY);
    return clearTimeout(handle);
  }, [emailSent]);

  const handleClick = async () => {
    if (emailSent || !user) {
      return;
    }
    setSending(true);
    await sendVerificationEmail();
    setSending(false);
    setEmailSent(true);
  };

  const logOut = () => {
    signOut();
  };

  return (
    <Card
      variant="hero"
      title="Request received."
      data-testid="ac-EmailVerificationPage"
      className="-mx-4 max-w-4xl sm:mx-auto"
    >
      <PageSeoTitle title="Email verification" />
      <div className="flex flex-col gap-6">
        <p className="text-grey-500">
          Your request has been received and is being processed. We will contact you promptly.
        </p>
        <div className="flex flex-col gap-4">
          <Heading>Email verification</Heading>
          <p>
            An e-mail from Portfolio Dragon has been sent to you to verify your e-mail address. Please follow the link
            in e-mail and then log in. This link will expire in 24 hours, a new email can be requested using the
            provided button below.
          </p>
          <p>
            <strong>Don't see the e-mail?</strong> Ensure it has not been sent to your spam folder, or try re-sending
            using the button below.
          </p>
        </div>
        {emailSent && (
          <Alert level="info">Email sent. Please wait for 2 minutes before requesting a new email confirmation.</Alert>
        )}
        {user && (
          <div className="flex gap-2">
            <Button onClick={handleClick} disabled={emailSent} loading={sending} loadingText="Sending...">
              Re-send verification e-mail
            </Button>
            <Button onClick={logOut}>Log out</Button>
          </div>
        )}
      </div>
    </Card>
  );
};
