import { type Action, type Subject } from '@aether/validation';

import { useAbilityContext } from '@/utils/auth';

import { NoAccess } from './NoAccess';

type WhenIProps = ({ can: Action; cannot?: never } | { can?: never; cannot: Action }) &
  ({ a: Subject; the?: never } | { a?: never; the: Subject }) & {
    show: JSX.Element;
    otherwiseShow?: JSX.Element | 'nothing';
  };

export const WhenI = (props: WhenIProps) => {
  const { can, cannot, show, otherwiseShow } = props;
  const ability = useAbilityContext();
  const action = can || cannot;
  const subject = props.a || props.the;
  const method: 'can' | 'cannot' = can ? 'can' : 'cannot';
  const isAllowed = ability[method](action, subject);

  if (isAllowed) return show;
  else if (!otherwiseShow) return <NoAccess />;
  else if (otherwiseShow === 'nothing') return undefined;
  else return otherwiseShow;
};
