import dayjs, { type Dayjs } from 'dayjs';

/**
 * Formats a Date, date string or timestamp to an ISO date string, without the time part.(YYYY-MM-DD)
 *
 * ie: 2021-12-31
 */
export function dateToISODateString(date: Dayjs | Date | string | number) {
  return dayjs(date).format('YYYY-MM-DD');
}

/**
 * Formats a Date, date string or timestamp to an ISO date string, with the time part.(YYYY-MM-DDTHH:mm:ssZ)
 *
 * ie: 2021-12-31
 */
export function dateToISODateTimeString(date: Dayjs | Date | string | number) {
  return dayjs(date).format();
}

export function subtractMonthsFromDate(date: Dayjs | Date | string | number, amount: number) {
  return dayjs(date).subtract(amount, 'month').toDate();
}

export function subtractYearsFromDate(date: Dayjs | Date | string | number, amount: number) {
  return dayjs(date).subtract(amount, 'year').toDate();
}

export const formatDateForDisplay = (date: Dayjs | Date | string | number): string => {
  return dayjs(date).format('DD/MM/YYYY');
};

export const formatDateTimeForDisplay = (date: Dayjs | Date | string | number): string => {
  return dayjs(date).format('DD/MM/YYYY HH:mm:ss');
};

export const isValidDate = (date: string): boolean => {
  const parsedDate = new Date(date);
  return !isNaN(parsedDate.getTime());
};
