import { useCallback, useEffect, useState, type CSSProperties } from 'react';
import { theme } from '@aether/ui';
import { useLocation } from 'react-router-dom';

import { queriesProgress } from '@/graphql/queries-progress-link';

export const PageLoader = () => {
  const location = useLocation();
  const [progress, setProgress] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [show, setShow] = useState(true);
  const createOnProgress = useCallback(() => {
    let timer: number | undefined = undefined;
    return (event: Event) => {
      const customEvent = event as CustomEvent;
      const progress = customEvent.detail;
      setProgress(progress);
      if (progress < 1 && timer) {
        window.clearTimeout(timer);
        timer = undefined;
      }
      if (progress === 1 && !timer) {
        timer = window.setTimeout(() => {
          setCompleted(true);
          window.setTimeout(() => {
            setShow(false);
          }, 1000);
        }, 1500);
      }
    };
  }, [setProgress, setCompleted, setShow]);
  useEffect(() => {
    const onProgress = createOnProgress();
    queriesProgress.addEventListener('progress', onProgress);

    return () => {
      queriesProgress.removeEventListener('progress', onProgress);
      setProgress(0);
      setCompleted(false);
      setShow(true);
    };
  }, [location, createOnProgress, setProgress, setCompleted, setShow]);
  const style: CSSProperties = {
    width: progress.toLocaleString('en-US', { style: 'percent', maximumFractionDigits: 2 }),
    backgroundColor: completed ? theme.colors.feat.benchmark : theme.colors.feat.portfolio,
  };

  return <>{show && <div className="fixed left-0 top-0 z-50 h-1" style={style}></div>}</>;
};
