import { useState } from 'react';
import { AlertDialog } from '@aether/ui';
import { Button } from '@aether/ui/Button';

import { useAuth } from '@/utils/auth';

import { getWebsiteOrigin } from '../utils/services/linking-service';

export function DisclaimerBanner() {
  const [showDialog, setShowDialog] = useState(true);
  const { isAuthenticated } = useAuth();

  if (localStorage.getItem('financial-investor') || !showDialog || window.Cypress || isAuthenticated) return;
  return (
    <>
      <AlertDialog.Root open={showDialog}>
        <AlertDialog.Content overlayOpacity="bg-opacity-50" className="">
          <AlertDialog.Body>
            <div className="flex flex-col gap-4">
              I hereby confirm that I am a Professional Investor.
              <div className="flex justify-end gap-4">
                <AlertDialog.Action asChild onClick={() => {}}>
                  <Button
                    variantColor="accent"
                    onClick={() => {
                      localStorage.setItem('financial-investor', 'true');
                      setShowDialog(false);
                    }}
                  >
                    Agree
                  </Button>
                </AlertDialog.Action>
                <AlertDialog.Action
                  asChild
                  onClick={() => {
                    localStorage.removeItem('financial-investor');
                    location.replace(getWebsiteOrigin() || '/');
                  }}
                >
                  <Button>Disagree</Button>
                </AlertDialog.Action>
              </div>
            </div>
          </AlertDialog.Body>
        </AlertDialog.Content>
      </AlertDialog.Root>
    </>
  );
}
