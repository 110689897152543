import { Alert } from '@aether/ui/Alert';

import { parseApolloError } from '@/graphql';

export function ApiErrorAlert(
  props: {
    title?: string;
    error: Error | string | string[] | undefined;
  } & React.ComponentProps<typeof Alert>
) {
  const { error, title, ...rest } = props;
  if (typeof error === 'undefined') {
    return null;
  }
  if (typeof error === 'string') {
    return <Alert level="error" title={error} {...rest} />;
  }
  if (Array.isArray(error)) {
    return (
      <Alert level="error" title={title || (error.length > 1 ? 'Some errors occurred' : '')} {...rest}>
        {error.length > 1 ? (
          <ul className="list-inside list-disc">
            {error.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        ) : (
          error[0]
        )}
      </Alert>
    );
  }
  const parsedError = parseApolloError(error);
  return <ApiErrorAlert error={parsedError} title={title} {...rest} />;
}
