import React, { useState } from 'react';
import { type MailQueryType } from '@aether/client-graphql/generated/graphql';
import { Alert, Button, RadioGroup, TextField } from '@aether/ui';

import { graphql, useMutation } from '@graphql';

import { useAuth } from '../../utils/auth';

const sendContactMessageMutation = graphql(/* GraphQL */ `
  mutation SendContactMessage($input: SendContactMessageInput!) {
    sendContactMessage(input: $input)
  }
`);

interface FormData {
  message: string;
  subject: string;
  queryType: MailQueryType;
}

const emptyFormData: FormData = {
  message: '',
  subject: '',
  queryType: 'Sales',
};

export const ContactForm = () => {
  const { user } = useAuth();
  const [formData, setFormData] = useState<FormData>(emptyFormData);
  const [showForm, setShowForm] = useState(true);
  const [sendMessage, { loading: sending, error }] = useMutation(sendContactMessageMutation, {
    variables: {
      input: {
        subject: formData.subject,
        message: formData.message,
        queryType: formData.queryType,
      },
    },
    onCompleted: () => {
      setFormData(emptyFormData);
      setShowForm(false);
    },
  });

  const send = () => {
    if (formData.subject && formData.message) {
      sendMessage();
    }
  };
  const options = [
    {
      id: '0',
      label: 'Sales',
    },
    {
      id: '1',
      label: 'Technical',
    },
  ];

  return (
    <div className="grid bg-white p-10">
      {showForm ? (
        <div>
          <form
            onSubmit={e => {
              e.preventDefault();
              send();
            }}
          >
            <div className="grid">
              <div>
                <h1>Contact us</h1>
                <p>We will aim to reply to your enquiry quickly.</p>
              </div>
              <div className="mb-2 w-1/2">
                <TextField id="email" label="Your e-mail" disabled required value={user?.email as string} />
              </div>
              <div className="mb-2 w-1/2">
                <TextField
                  id="subject"
                  label="Subject"
                  required
                  minLength={1}
                  value={formData.subject}
                  onChange={subject => setFormData(formData => ({ ...formData, subject }))}
                />
              </div>
              <div className="w-1/2">
                <label htmlFor="queryType">Query Type</label>
                <RadioGroup.Group
                  onValueChange={value => {
                    const selected = options.find(o => o.id === value);
                    setFormData({
                      ...formData,
                      queryType: selected?.label as MailQueryType,
                    });
                  }}
                >
                  {options.map(option => (
                    <RadioGroup.Radio key={option.id} value={option.id}>
                      {option.label}
                    </RadioGroup.Radio>
                  ))}
                </RadioGroup.Group>
              </div>
              <div className="mb-2 w-1/2">
                <TextField
                  id="message-input"
                  label="Please enter your message"
                  required
                  value={formData.message}
                  onChange={(e: any) => setFormData({ ...formData, message: e })}
                />
              </div>
              <div className="">
                {error && (
                  <Alert level="error">
                    {'Failed to send contact message. Please contact us via e-mail at support@arabesque.ai'}
                  </Alert>
                )}
              </div>
              <div>
                <Button variantColor="primary" loading={sending} loadingText="Sending..." type="submit">
                  Send
                </Button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div>
          <h2>Message sent</h2>
          <p>Your message has been sent.</p>
          <Button variantColor="primary" onClick={() => setShowForm(true)}>
            Send another
          </Button>
        </div>
      )}
    </div>
  );
};
