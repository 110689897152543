import * as React from 'react';
import * as RadixTooltip from '@radix-ui/react-tooltip';

import { cn } from '../utils';

export type TooltipProviderProps = RadixTooltip.TooltipProviderProps;

export function TooltipProvider(props: TooltipProviderProps) {
  const { delayDuration = 300, ...rest } = props;
  return <RadixTooltip.Provider delayDuration={delayDuration} {...rest} />;
}

const TooltipRoot = RadixTooltip.Root;

const TooltipTrigger = RadixTooltip.Trigger;

export type TooltipContentProps = RadixTooltip.TooltipContentProps;

function TooltipContent(props: TooltipContentProps) {
  const { className, children, sideOffset = 6, collisionPadding = 8, ...rest } = props;
  return (
    <RadixTooltip.Portal>
      <RadixTooltip.Content
        className={cn(
          'z-50 rounded border border-white bg-white text-xs font-medium text-white shadow-tooltip',
          className
        )}
        sideOffset={sideOffset}
        collisionPadding={collisionPadding}
        {...rest}
      >
        <div className="inline-block rounded-[3px] bg-black px-4 py-2">{children}</div>
        <RadixTooltip.Arrow className="-m-1" width={18} height={9} />
      </RadixTooltip.Content>
    </RadixTooltip.Portal>
  );
}

export type TooltipProps = Omit<RadixTooltip.TooltipContentProps & RadixTooltip.TooltipProps, 'content'> & {
  asChild?: boolean;
  children: React.ReactNode;
  content?: React.ReactNode;
};

export function Tooltip(props: TooltipProps) {
  const { children, content, asChild, open, defaultOpen, onOpenChange, delayDuration, ...rest } = props;
  return (
    <TooltipRoot {...{ open, defaultOpen, onOpenChange, delayDuration }}>
      <TooltipTrigger asChild={asChild}>{children}</TooltipTrigger>
      <TooltipContent {...rest}>{content}</TooltipContent>
    </TooltipRoot>
  );
}

export const TooltipParts = { TooltipProvider, TooltipRoot, TooltipTrigger, TooltipContent };
