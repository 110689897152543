import { useCallback, useState } from 'react';
import { Button, Checkbox, Dialog, IconButton } from '@aether/ui';

export const availableFeaturesMap = new Map([['cacheBustToken', '💥']]);

export const syncFeatureFlags = () => {
  const searchParams = new URLSearchParams(window.location.search);
  availableFeaturesMap.forEach((icon, key) => {
    if (searchParams.has(key)) {
      const value = searchParams.get(key) as string; // casting since we know it exists due to if clause
      console.info(`Feature flag ${key} ${icon} is: ${value}`);
      if (value !== 'false')
        localStorage.setItem(key, value); // persist
      else localStorage.removeItem(key); // remove
    }
  });
};

export const FeatureController = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {/* Secret button to open the feature dialog when clicked 5x */}
      <Button
        onClick={event => event.detail === 5 && setIsOpen(true)}
        className="fixed bottom-0 left-0 z-50 h-2 w-2 cursor-default opacity-0"
      />
      <FeatureDialog isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};

export function FeatureDialog({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  const [_, setRefresh] = useState(false);

  const onCheckedChange = useCallback((key: string, checked: boolean) => {
    if (checked) localStorage.setItem(key, 'true');
    else localStorage.removeItem(key);
    setRefresh(prev => !prev);
  }, []);

  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Content>
        <Dialog.Title>Features</Dialog.Title>
        <Dialog.Body>
          <ul>
            {[...availableFeaturesMap].map(([key, icon]) => {
              if (key === 'cacheBustToken') {
                const token = localStorage.getItem(key);
                return (
                  <li key={key}>
                    {icon} cacheBustToken = {token ? <code className="bg-gray-100 p-1 text-xs">{token}</code> : 'empty'}{' '}
                    {token ? (
                      <IconButton
                        variant="ghost"
                        size="small"
                        icon="ic:outline-delete"
                        onClick={() => onCheckedChange(key, false)}
                      />
                    ) : (
                      <span className="text-xs">(needs to be passed in url)</span>
                    )}
                  </li>
                );
              }
              return (
                <li key={key}>
                  {icon} {key} = {localStorage.getItem(key) || 'false'}{' '}
                  <Checkbox
                    checked={localStorage.getItem(key) ? true : false}
                    onCheckedChange={(checked: boolean) => onCheckedChange(key, checked)}
                  />
                </li>
              );
            })}
          </ul>
          <Dialog.Close variant="unstyled" className="mt-5">
            <Button onClick={onClose}>Okay</Button>
          </Dialog.Close>
        </Dialog.Body>
      </Dialog.Content>
    </Dialog.Root>
  );
}
