import { getWebsiteOrigin } from '@aether/utils/services/linking-service';
import { Link } from 'react-router-dom';

import { cn } from '../../utils';

export function Footer({ className, isAuthenticated }: { className?: string; isAuthenticated?: boolean }) {
  const getDragonURL = getWebsiteOrigin() || '/';
  return (
    <footer
      className={cn(
        'mb-4 flex flex-col justify-between gap-y-5 border-t border-black px-6 py-4 text-center sm:flex-row sm:gap-y-0 sm:text-left',
        className
      )}
    >
      <div className="text-sm">&copy; {new Date().getFullYear()}, Portfolio Dragon</div>
      <nav className="mr-4 flex flex-row gap-4 text-center text-sm text-grey-700 sm:text-left">
        <Link to={`${getDragonURL}/platform-service-terms`}>Platform Service Terms</Link>
        <Link to={`${getDragonURL}/privacy-policy`}>Privacy Policy</Link>
        <Link to={`${getDragonURL}/cookie-policy`}>Cookie Policy</Link>
        <Link to={isAuthenticated ? '/contact' : 'https://www.arabesque.com/ai/contact-us/'}>Contact Us</Link>
        {isAuthenticated && <Link to="/faq">Help</Link>}
      </nav>
    </footer>
  );
}
