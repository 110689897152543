import generatedCountries from './generated/countries';

export type CountryCode = (typeof generatedCountries)[number]['code'];

export type Country = {
  identifier: CountryCode;
  market: 'developed' | 'emerging' | 'frontier' | 'other';
  name: string;
  region: ['Global', 'EMEA' | 'APAC' | 'Americas'];
};

const regionMapping: { [key: string]: 'EMEA' | 'APAC' | 'Americas' } = {
  europe: 'EMEA',
  middle_east: 'EMEA',
  africa: 'EMEA',
  asia: 'APAC',
  pacific: 'APAC',
  lat_am: 'Americas',
  north_america: 'Americas',
};

export const countries: Country[] = generatedCountries.map(country => ({
  name: country.name,
  identifier: country.code,
  region: ['Global', regionMapping[country.regionCode]],
  market: country.market,
}));

export const regions = new Map([
  ['Global', countries.filter(c => c.region.includes('Global'))],
  ['Global developed', countries.filter(c => c.market === 'developed')],
  ['USA', [countries.find(c => c.identifier === 'US')]],
  ['Europe developed', countries.filter(c => c.region.includes('EMEA') && c.market === 'developed')],
  ['EMEA', countries.filter(c => c.region.includes('EMEA'))],
  ['APAC', countries.filter(c => c.region.includes('APAC'))],
  ['APAC developed', countries.filter(c => c.region.includes('APAC') && c.market === 'developed')],
  ['Emerging markets', countries.filter(c => c.market === 'emerging')],
  ['Americas emerging', countries.filter(c => c.region.includes('Americas') && c.market === 'emerging')],
  [
    'Frontier & others',
    countries.filter(c => c.market === 'frontier' || !['frontier', 'emerging', 'developed'].includes(c.market)),
  ],
]);

/**
 * For the registration form
 */
export const blockedCountriesISO = [
  'AF',
  'BY',
  'BA',
  'MM',
  'CF',
  'CU',
  'CG',
  'CD',
  'KP',
  'KR',
  'ET',
  'GN',
  'GW',
  'HT',
  'IR',
  'IQ',
  'LB',
  'LY',
  'ML',
  'NE',
  'RU',
  'SO',
  'SD',
  'SS',
  'SY',
  'UA',
  'VE',
  'YE',
  'ZW',
];

export const countryNameToISO2 = generatedCountries.reduce(
  (acc, curr) => {
    acc[curr.name] = curr.code;
    return acc;
  },
  {} as Record<string, CountryCode>
);

export const iso2ToCountryName = generatedCountries.reduce(
  (acc, curr) => {
    acc[curr.code] = curr.name;
    return acc;
  },
  {} as Record<CountryCode, string>
);
