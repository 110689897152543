import * as React from 'react';
import { Slot, Slottable } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { Icon } from '../Icon';
import { Spinner } from '../Spinner';
import { cn, useForwardedRef } from '../utils';

export type ButtonProps = Omit<React.ComponentProps<'button'>, 'ref'> &
  VariantProps<typeof buttonClasses> & {
    asChild?: boolean;
    iconBefore?: string;
    iconAfter?: string;
    loadingText?: string;
    className?: string;
    iconClassName?: string;
  };

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, forwardedRef) => {
  const {
    asChild,
    iconAfter,
    iconBefore,
    children,
    variant,
    variantColor,
    size,
    className,
    disabled,
    loading,
    loadingText,
    iconClassName,
    ...rest
  } = props;
  const Comp = (asChild ? Slot : 'button') as 'button';
  const ref = useForwardedRef(forwardedRef);
  return (
    <Comp
      ref={ref}
      className={cn('ac-btn', buttonClasses({ variant, size, variantColor, disabled, loading }), className)}
      disabled={Boolean(disabled || loading)}
      {...rest}
    >
      {!loading && iconBefore && (
        <Icon icon={iconBefore} className={cn(iconClasses({ size, variant }), iconClassName)} />
      )}
      {loading && <Spinner className={iconClasses({ size, variant })} />}
      {loading ? loadingText : <Slottable>{children}</Slottable>}
      {!loading && iconAfter && <Icon icon={iconAfter} className={cn(iconClasses({ size, variant }), iconClassName)} />}
    </Comp>
  );
});

const buttonClasses = cva(
  ['inline-flex', 'rounded-l rounded-r', 'items-center', 'justify-center', 'whitespace-nowrap'],
  {
    variants: {
      variant: {
        unstyled: 'rounded-none',
        plain: 'shadow-card',
        ghost: '',
        outline: '',
        hero: 'h-20 w-full border-2 border-dashed',
      },
      size: {
        xsmall: 'h-4 gap-2 px-2 text-xs font-normal leading-4',
        smaller: 'h-6 gap-2 p-2 text-xs font-normal leading-[0.5rem]',
        small: 'h-7 gap-2 px-4 py-1 text-xs font-normal leading-5',
        medium: 'h-9 gap-2 px-4 py-2 text-sm font-normal leading-5',
        ml: 'h-11 gap-2 px-4 py-3 text-sm font-normal leading-5',
        large: 'h-14 gap-2 px-7 py-3 text-sm font-bold leading-8',
      },
      variantColor: {
        primary: '',
        accent: '',
        accentlight: '',
        secondary: '',
        warning: '',
        danger: '',
        unstyled: '',
      },
      disabled: {
        true: 'cursor-not-allowed',
      },
      loading: {
        true: 'cursor-progress',
      },
    },
    compoundVariants: [
      {
        variant: 'plain',
        variantColor: 'primary',
        disabled: false,
        className: 'bg-grey-850 text-white',
      },
      {
        variant: 'plain',
        variantColor: 'primary',
        disabled: false,
        loading: false,
        className: 'hover:bg-accent hover:text-white',
      },
      {
        variant: 'plain',
        variantColor: 'accent',
        disabled: false,
        className: 'bg-portfolio-500 text-white',
      },
      {
        variant: 'plain',
        variantColor: 'accent',
        disabled: false,
        loading: false,
        className: 'hover:bg-grey-850 hover:text-white',
      },
      {
        variant: 'plain',
        variantColor: 'secondary',
        disabled: false,
        className: 'bg-white text-black',
      },
      {
        variant: 'plain',
        variantColor: 'secondary',
        disabled: false,
        loading: false,
        className: 'hover:bg-grey-850 hover:text-white',
      },
      {
        variant: 'plain',
        variantColor: 'warning',
        disabled: false,
        className: 'bg-orange-400 text-white',
      },
      {
        variant: 'plain',
        variantColor: 'warning',
        loading: false,
        disabled: false,
        className: 'hover:bg-orange-600',
      },
      {
        variant: 'plain',
        variantColor: 'danger',
        disabled: false,
        className: 'bg-error-400 text-white',
      },
      {
        variant: 'plain',
        variantColor: 'danger',
        disabled: false,
        loading: false,
        className: 'hover:bg-error-600',
      },
      {
        variant: 'ghost',
        variantColor: 'primary',
        disabled: false,
        className: 'text-black',
      },
      {
        variant: 'ghost',
        variantColor: 'primary',
        disabled: false,
        loading: false,
        className: 'hover:bg-grey-850 hover:text-white',
      },
      {
        variant: 'ghost',
        variantColor: 'accent',
        disabled: false,
        className: 'text-accent',
      },
      {
        variant: 'ghost',
        variantColor: 'accentlight',
        disabled: false,
        className: 'underline hover:bg-accentlight hover:text-accent',
      },
      {
        variant: 'ghost',
        variantColor: 'accent',
        disabled: false,
        loading: false,
        className: 'hover:bg-accent hover:text-white',
      },
      {
        variant: 'ghost',
        variantColor: 'accentlight',
        disabled: false,
        loading: false,
        className: 'underline hover:bg-accentlight hover:text-accent',
      },
      {
        variant: 'ghost',
        variantColor: 'secondary',
        disabled: false,
        className: 'text-gray-600',
      },
      {
        variant: 'ghost',
        variantColor: 'secondary',
        disabled: false,
        loading: false,
        className: 'hover:text-black',
      },
      {
        variant: 'ghost',
        variantColor: 'warning',
        disabled: false,
        className: 'text-orange-600',
      },
      {
        variant: 'ghost',
        variantColor: 'warning',
        loading: false,
        disabled: false,
        className: 'hover:text-orange-700',
      },
      {
        variant: 'ghost',
        variantColor: 'danger',
        disabled: false,
        className: 'text-error-600',
      },
      {
        variant: 'ghost',
        variantColor: 'danger',
        disabled: false,
        loading: false,
        className: 'hover:text-error-700',
      },
      {
        variant: 'outline',
        variantColor: 'primary',
        disabled: false,
        className: 'border border-black text-black',
      },
      {
        variant: 'outline',
        variantColor: 'primary',
        disabled: false,
        loading: false,
        className: 'hover:border-grey-800 hover:text-grey-800',
      },
      {
        variant: 'outline',
        variantColor: 'accent',
        disabled: false,
        className: 'border border-accent text-accent',
      },
      {
        variant: 'outline',
        variantColor: 'accent',
        disabled: false,
        loading: false,
        className: 'hover:border-portfolio-800 hover:text-portfolio-800',
      },
      {
        variant: 'outline',
        variantColor: 'accentlight',
        disabled: false,
        className: 'border border-portfolio-300 text-portfolio-300',
      },
      {
        variant: 'outline',
        variantColor: 'accentlight',
        disabled: false,
        loading: false,
        className: 'hover:border-accent hover:text-accent',
      },
      {
        variant: 'outline',
        variantColor: 'secondary',
        disabled: false,
        className: 'border border-grey-600 text-grey-600',
      },
      {
        variant: 'outline',
        variantColor: 'secondary',
        disabled: false,
        loading: false,
        className: 'hover:border-text-black hover:text-black',
      },
      {
        variant: 'outline',
        variantColor: 'warning',
        disabled: false,
        className: 'border border-orange-600 text-orange-600',
      },
      {
        variant: 'outline',
        variantColor: 'warning',
        loading: false,
        disabled: false,
        className: 'hover:border-orange-700 hover:text-orange-700',
      },
      {
        variant: 'outline',
        variantColor: 'danger',
        disabled: false,
        className: 'border border-error-600 text-error-600',
      },
      {
        variant: 'outline',
        variantColor: 'danger',
        disabled: false,
        loading: false,
        className: 'hover:border-error-700 hover:text-error-700',
      },
      {
        variant: 'hero',
        variantColor: 'primary',
        disabled: false,
        className: 'border-grey-300 text-black',
      },
      {
        variant: 'hero',
        variantColor: 'primary',
        disabled: false,
        loading: false,
        className: 'hover:border-grey-500 hover:text-grey-800',
      },
      {
        variant: 'hero',
        variantColor: 'accent',
        disabled: false,
        className: 'border-portfolio-300 text-accent',
      },
      {
        variant: 'hero',
        variantColor: 'accent',
        disabled: false,
        loading: false,
        className: 'hover:border-portfolio-500 hover:text-portfolio-800',
      },
      {
        variant: 'hero',
        variantColor: 'accentlight',
        disabled: false,
        className: 'border-portfolio-50 text-portfolio-300',
      },
      {
        variant: 'hero',
        variantColor: 'accentlight',
        disabled: false,
        loading: false,
        className: 'hover:border-portfolio-100 hover:text-accent',
      },
      {
        variant: 'hero',
        variantColor: 'secondary',
        disabled: false,
        className: 'border-grey-300 text-grey-600',
      },
      {
        variant: 'hero',
        variantColor: 'secondary',
        disabled: false,
        loading: false,
        className: 'hover:border-grey-500 hover:text-black',
      },
      {
        variant: 'hero',
        variantColor: 'warning',
        disabled: false,
        className: 'border-orange-300 text-orange-600',
      },
      {
        variant: 'hero',
        variantColor: 'warning',
        loading: false,
        disabled: false,
        className: 'hover:border-orange-500 hover:text-orange-700',
      },
      {
        variant: 'hero',
        variantColor: 'danger',
        disabled: false,
        className: 'border-error-200 text-error-500',
      },
      {
        variant: 'hero',
        variantColor: 'danger',
        disabled: false,
        loading: false,
        className: 'hover:border-error-500 hover:text-error-700',
      },
      {
        variant: 'hero',
        size: 'large',
        className: 'h-[72px] p-4 text-sm font-normal',
      },
      { variant: 'plain', disabled: true, className: 'bg-grey-50 text-grey-300' },
      { variant: 'ghost', disabled: true, className: 'text-grey-300' },
      { variant: 'outline', disabled: true, className: 'border border-grey-300 text-grey-300' },
      {
        variant: 'outline',
        disabled: true,
        variantColor: 'accent',
        className: 'border border-portfolio-100 text-portfolio-100',
      },
      { variant: 'hero', disabled: true, className: 'border-grey-100 text-grey-300' },
    ],
    defaultVariants: {
      variant: 'plain',
      size: 'medium',
      variantColor: 'primary',
      disabled: false,
      loading: false,
    },
  }
);

const iconClasses = cva(['mi-outlined', 'm-0'], {
  variants: {
    variant: {
      plain: '',
      ghost: '',
      outline: '',
      hero: '',
      unstyled: '',
    },
    size: {
      xsmall: 'text-base leading-4',
      smaller: 'text-base leading-[0.5rem]',
      small: 'text-base leading-5',
      medium: 'text-base leading-5',
      ml: 'text-base',
      large: 'text-2xl',
    },
  },
  compoundVariants: [
    {
      variant: 'hero',
      size: 'large',
      className: 'text-base',
    },
  ],
  defaultVariants: {
    variant: 'plain',
    size: 'medium',
  },
});
