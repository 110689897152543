import { TypedObjectKeys } from '@aether/utils';
import { gold, goldDark } from '@radix-ui/colors';
import type { Config } from 'tailwindcss';

const colors = {
  grey: {
    50: '#f1f1f1',
    100: '#d4d4d4',
    200: '#c0c0c0',
    300: '#a3a3a3',
    400: '#919191',
    500: '#757575',
    600: '#6a6a6a',
    700: '#535353',
    800: '#404040',
    900: '#313131',
  },
  portfolio: {
    50: '#eeefff',
    100: '#cccdff',
    200: '#b3b5ff',
    300: '#9093ff',
    400: '#7a7eff',
    500: '#595eff',
    600: '#5156e8',
    700: '#3f43b5',
    800: '#31348c',
    900: '#25276b',
  },
  benchmark: {
    50: '#e8f5f3',
    100: '#b9dedb',
    200: '#97cfca',
    300: '#67b9b1',
    400: '#49aba2',
    500: '#1c968b',
    600: '#19897e',
    700: '#146b63',
    800: '#0f534c',
    900: '#0c3f3a',
  },
};

// a type that for the string 'gold1' returns 1, for 'gold2' returns 2, etc.
type ExtractNumber<S extends string, P extends string> = S extends `${P}${infer N}` ? N : never;

const reformattedGold = TypedObjectKeys(gold).reduce(
  (acc, key) => {
    const number = key.replace('gold', '') as ExtractNumber<keyof typeof gold, 'gold'>;
    acc[number] = gold[key].toString();
    return acc;
  },
  {} as Record<ExtractNumber<keyof typeof gold, 'gold'>, string>
);

const reformattedGoldDark = TypedObjectKeys(goldDark).reduce(
  (acc, key) => {
    const number = key.replace('gold', '') as ExtractNumber<keyof typeof goldDark, 'gold'>;
    acc[number] = goldDark[key].toString();
    return acc;
  },
  {} as Record<ExtractNumber<keyof typeof goldDark, 'gold'>, string>
);

const theme = {
  colors: {
    // aliases
    accent: '#595eff', // TODO: Replace with portfolio-500
    accentlight: '#DEDFFF', // TODO: Replace with portfolio-50
    feat: {
      portfolio: colors.grey[500], // TODO: Replace with portfolio-500
      benchmark: '#43aaa1', // TODO: Replace with benchmark-500
      enhanced: '#ed8b00', // TODO: Replace with warning-500
    },
    text: {
      primary: colors.grey[900],
      secondary: colors.grey[600],
      disabled: colors.grey[200],
    },
    border: {
      light: colors.grey[100],
      medium: colors.grey[300],
      dark: colors.grey[600],
    },
    gold: reformattedGold,
    'gold-dark': reformattedGoldDark,
    // TODO: Replace with grey-ds palette
    grey: {
      25: '#f6f6f680',
      50: '#f6f6f6',
      100: '#e3e3e3',
      150: '#d6d6d6',
      200: '#c8c8c8',
      300: '#acacac',
      400: '#919191',
      500: '#757575',
      600: '#5e5e5e',
      700: '#464646',
      800: '#2f2f2f',
      850: '#232323',
      900: '#171717',
      950: '#0c0c0c',
    },
    'grey-ds': colors.grey,
    portfolio: colors.portfolio,
    benchmark: colors.benchmark,
    error: {
      50: '#fce8e7',
      100: '#f5b8b4',
      200: '#f0968f',
      300: '#e9665c',
      400: '#e5493d',
      500: '#de1b0c',
      600: '#ca190b',
      700: '#9e1309',
      800: '#7a0f07',
      900: '#5d0b05',
    },
    success: {
      50: '#e6f4f0',
      100: '#b0ded1',
      200: '#8aceba',
      300: '#54b89b',
      400: '#33aa87',
      500: '#009569',
      600: '#008860',
      700: '#006a4b',
      800: '#00523a',
      900: '#003f2c',
    },
    warning: {
      50: '#fdf3e6',
      100: '#f9dbb0',
      200: '#f7ca8a',
      300: '#f3b154',
      400: '#f1a233',
      500: '#ed8b00',
      600: '#d87e00',
      700: '#a86300',
      800: '#824c00',
      900: '#643a00',
    },
    info: {
      50: '#e6f1fa',
      100: '#b0d5f1',
      200: '#8ac0ea',
      300: '#54a3e0',
      400: '#3391da',
      500: '#0076d1',
      600: '#006bbe',
      700: '#005494',
      800: '#004173',
      900: '#003258',
    },
    chart: {
      '001': '#5b8f4a',
      '002': '#106e9a',
      '003': '#ad243b',
      '004': '#563b72',
      '005': '#c38639',
      '006': '#a03422',
      '007': '#6ba857',
      '008': '#1581b4',
      '009': '#d02c49',
      '010': '#654587',
      '011': '#db9c44',
      '012': '#b93d28',
      '013': '#7fc367',
      '014': '#1e96ce',
      '015': '#e53355',
      '016': '#77529e',
      '017': '#eeb64f',
      '018': '#d2472f',
      '019': '#98cc84',
      '020': '#5ca6d5',
      '021': '#e9646d',
      '022': '#8b6ead',
      '023': '#f0c272',
      '024': '#da6c49',
      '025': '#b1d7a0',
      '026': '#85b8dd',
      '027': '#ed8a88',
      '028': '#a18bbf',
      '029': '#f3cf91',
      '030': '#e18c6d',
      '031': '#cae3be',
      '032': '#abcce8',
      '033': '#f3aea9',
      '034': '#bbabd2',
      '035': '#f7deb3',
      '036': '#eaae94',
    },
  },
  boxShadow: {
    card: '0 2px 4px rgba(0, 0, 0, 0.15)',
    dropdown: '0 2px 4px rgba(0, 0, 0, 0.15)',
    tooltip: '0 2px 4px rgba(0, 0, 0, 0.15)',
  },
  animation: {
    dialog: 'contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1)',
    overlay: 'overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1)',
    slideUpAndFade: 'slideUpAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1)',
    slideRightAndFade: 'slideRightAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1)',
    slideDownAndFade: 'slideDownAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1)',
    slideLeftAndFade: 'slideLeftAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1)',
    timedNotification: 'expandWidth 6s linear forwards',
    'reverse-spin': 'reverse-spin 1s linear infinite',
    'fade-in': 'fade-in 350ms cubic-bezier(0.16, 1, 0.3, 1) forwards',
  },
  keyframes: {
    contentShow: {
      from: {
        opacity: '0',
        transform: 'translate(-50%, -48%) scale(0.96)',
      },
      to: {
        opacity: '1',
        transform: 'translate(-50%, -50%) scale(1)',
      },
    },
    overlayShow: {
      from: {
        opacity: '0',
      },
      to: {
        opacity: '1',
      },
    },
    expandWidth: {
      from: {
        width: '0%',
      },
      to: {
        width: '100%',
      },
    },
    slideUpAndFade: {
      from: { opacity: '0', transform: 'translateY(2px)' },
      to: { opacity: '1', transform: 'translateY(0)' },
    },
    slideRightAndFade: {
      from: { opacity: '0', transform: 'translateX(-2px)' },
      to: { opacity: '1', transform: 'translateX(0)' },
    },
    slideDownAndFade: {
      from: { opacity: '0', transform: 'translateY(-2px)' },
      to: { opacity: '1', transform: 'translateY(0)' },
    },
    slideLeftAndFade: {
      from: { opacity: '0', transform: 'translateX(2px)' },
      to: { opacity: '1', transform: 'translateX(0)' },
    },
    'reverse-spin': {
      from: {
        transform: 'rotate(360deg)',
      },
    },
    'fade-in': {
      from: {
        opacity: '0',
      },
      to: {
        opacity: '1',
      },
    },
  },
  fontFamily: {
    mono: 'GeistMono, ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
  },
  fontSize: {
    xxs: '10px',
    home: '40px',
    hero: '40px',
    'heading-1': ['3rem', { lineHeight: '3.5rem', fontWeight: '700', letterSpacing: '0px' }],
    'heading-2': ['2.5rem', { lineHeight: '3rem', fontWeight: '700', letterSpacing: '0px' }],
    'heading-3': ['2rem', { lineHeight: '2.5rem', fontWeight: '700', letterSpacing: '0px' }],
    'heading-4': ['1.75rem', { lineHeight: '2rem', fontWeight: '700', letterSpacing: '0px' }],
    'heading-5': ['1.5rem', { lineHeight: '2rem', fontWeight: '700', letterSpacing: '0px' }],
    'heading-6': ['1.25rem', { lineHeight: '1.5rem', fontWeight: '600', letterSpacing: '0px' }],
    body: ['0.875rem', { lineHeight: '1.5rem', fontWeight: '400', letterSpacing: '0px' }],
    'body-large': ['1rem', { lineHeight: '1.5rem', fontWeight: '400', letterSpacing: '0px' }],
    'body-small': ['0.75rem', { lineHeight: '1rem', fontWeight: '400', letterSpacing: '0px' }],
    'card-title': ['0.875rem', { lineHeight: '1.5rem', fontWeight: '700', letterSpacing: '0px' }],
    'title-1': ['1rem', { lineHeight: '1.5rem', fontWeight: '500', letterSpacing: '0px' }],
    small: ['0.625rem', { lineHeight: '1rem', fontWeight: '400', letterSpacing: '0px' }],
    'overline-1': ['1rem', { lineHeight: '1.5rem', fontWeight: '700', letterSpacing: '1px' }],
    'overline-2': ['0.875rem', { lineHeight: '1.5rem', fontWeight: '500', letterSpacing: '1px' }],
  },
  lineHeight: {
    11: '2.75rem',
    12: '3rem',
    13: '3.25rem',
    14: '3.5rem',
    15: '3.75rem',
    16: '4rem',
  },
  aspectRatio: {
    auto: 'auto',
    square: '1 / 1',
  },
  screens: {
    '2xl': '1513px',
  },
} satisfies Config['theme'];

export default theme;
