import { Navigate } from 'react-router-dom';

import { PageHeader, PageTitle } from '@/components/PageHeader';
import { WhenI } from '@/components/Permissions/WhenI';

import { RouterError } from '../Error/RouterError';
import { RestrictedPage } from '../StrategyDetails/components/RestrictedPage';

export const configurationRouter = {
  lazy: async () => {
    const { ConfigurationLayout } = await import('./ConfigurationLayout');
    const Component = () => (
      <WhenI
        can="read"
        the="ConfigurationPage"
        show={<ConfigurationLayout />}
        otherwiseShow={
          <div>
            <PageHeader>
              <PageTitle className="my-4">Configuration</PageTitle>
            </PageHeader>
            <RestrictedPage />
          </div>
        }
      />
    );
    Component.displayName = 'ConfigurationPage';
    return {
      Component,
      ErrorBoundary: () => <RouterError />,
    };
  },
  children: [
    {
      index: true,
      element: <Navigate to="universe" />,
    },
    {
      path: 'universe',
      lazy: async () => {
        const { Universe } = await import('./pages/universe/Universe');
        return {
          Component: Universe,
          ErrorBoundary: () => <RouterError />,
        };
      },
    },
    {
      // path: 'rules-and-objectives',
      path: 'constraints',
      lazy: async () => {
        const { Constraints } = await import('./pages/constraints/Constraints');
        return {
          Component: Constraints,
          ErrorBoundary: () => <RouterError />,
        };
      },
    },
    {
      path: 'summary',
      lazy: async () => {
        const { Summary } = await import('./pages/Summary');
        return {
          Component: Summary,
          ErrorBoundary: () => <RouterError />,
        };
      },
    },
  ],
};
