import { Helmet } from 'react-helmet-async';

export function PageSeoTitle({
  title,
  keywords = 'Arabesque, Portfolio Dragon, AI, machine learning, portfolio management',
  description = 'Create, augment and analyse your portfolio at scale and harness the power of machine learning with our cutting-edge AI.',
  titleTemplate = '%s - Portfolio Dragon',
}: {
  title: string;
  keywords?: string;
  description?: string;
  titleTemplate?: string;
}) {
  return (
    <Helmet titleTemplate={titleTemplate} defaultTitle="Arabesque - Portfolio Dragon">
      <title>{title}</title>
      <meta key={'og_title'} property="og:title" content={title} />
      <meta name="keywords" content={keywords} />
      <meta name="description" content={description} />
    </Helmet>
  );
}
